<template>
    <div>
        <RecordList title="Utilities" :records="records" :sort-by="[]" :filter-by="[]">

            <!-- Title -->
            <template v-slot:title-actions="slotProps">
                <ActionButtonStack>
                    <template v-slot:actions>
                        <!-- <ActionButton text="Add" @click="addRecord"><v-icon name="plus" class="fill-current"></v-icon></ActionButton> -->
                    </template>
                    <template v-slot:important-actions>
                        <!-- <RecordListActionButton important text="Add" @click="addRecord"><v-icon name="plus" class="fill-current"></v-icon></RecordListActionButton> -->
                    </template>
                </ActionButtonStack>
            </template>

            <!-- List Header -->
            <template v-slot:header="slotProps">
                <RecordListItem header>
                    <div class="flex items-center font-bold">
                        <!-- <RecordListSortableHeaderItem class="flex-1" field="name" @click="slotProps.list.sortRecords('name')">Name</RecordListSortableHeaderItem> -->
                        <div class="flex-1">Type</div>
                        <div class="w-1/6">Connection</div>
                        <div class="w-1/6">GHG Emissions (tonnes/yr)</div>
                        <div class="w-1/6">Rated Consumption (GJ/yr)</div>
                        <div class="w-1/6">Equivalent Units (/yr)</div>


                    </div>
                </RecordListItem>
            </template>

            <!-- Records -->
            <template v-slot:record="slotProps">
                <RecordListItem :visible="slotProps.record.deleted_at == null">

                    <!-- Details  -->
                    <div class="flex items-center">
                        <div class="flex flex-1 items-center">
                            <div class="mr-2">
                                <img :src="image(slotProps.record.image_url)" class="w-6 h-6 mr-2 inline-block"/>
                            </div>
                            <a href="#" class="hover:underline" @click.prevent="viewRecord(slotProps.record)">{{ slotProps.record.type.name }}</a>
                        </div>
                        <div class="w-1/6">{{ slotProps.record.name }}</div>
                        <div class="w-1/6">{{ slotProps.record.pivot.ghg_emissions }}</div>
                        <div class="w-1/6">{{ slotProps.record.pivot.rated_consumption }}</div>
                        <div class="w-1/6">{{ slotProps.record.pivot.equivalent_units }}</div>

                        
                    </div>

                    <!-- Important Actions -->
                    <template v-slot:important-actions>
                        <!-- <RecordListActionButton important text="Edit" @click="editRecord(slotProps.record)"><v-icon name="pen" class="fill-current"></v-icon></RecordListActionButton> -->
                    </template>

                    <!-- Other Actions -->
                    <template v-slot:actions>
                        <!-- <RecordListActionButton text="Delete" @click="deleteRecord(slotProps.record)"><v-icon name="trash" class="fill-current"></v-icon></RecordListActionButton> -->
                    </template>

                </RecordListItem>
            </template>
        </RecordList>

        <!-- Modals -->
        <CreateUtilityModal/>
        <UpdateUtilityModal/>
        <ViewUtilityModal/>

        <!-- {{ records }} -->
    </div>

</template>

<script>
import RecordList from '../../../Components/RecordList/RecordList.vue';
import RecordListItem from '../../../Components/RecordList/RecordListItem.vue';
import RecordListActionButton from '../../../Components/RecordList/RecordListActionButton.vue';
import CreateUtilityModal from './CreateUtilityModal.vue';
import UpdateUtilityModal from './UpdateUtilityModal.vue';
import ViewUtilityModal from './ViewUtilityModal.vue';

export default {
    components: {
        RecordList,
        RecordListItem,
        RecordListActionButton,
        CreateUtilityModal,
        UpdateUtilityModal,
        ViewUtilityModal
    },
    props: {
        records: {
            type: Array,
            required: true,
            default: () => []
        },
    },
    data() {
        return {
        }
    },
    computed: {
        //
    },
    methods: {
        async editRecord(record)
        {
            alert('Edit: ' + record.id);
            console.log('Edit', record);
            // await this.$store.dispatch('appliances/getUtility', record.id);
            // this.$modal.show('update-appliance-modal');
        },
        viewRecord(record)
        {
            this.$store.commit('utilities/setUtility', record);
            this.$modal.show('view-utility-modal')
            // alert('View');
        },
        addRecord()
        {
            this.$modal.show('create-utility-modal');
        },
        deleteRecord(record)
        {
            alert('Delete: ' + record.id);
            console.log('Delete', record);
        },
        duplicateRecord(record)
        {
            alert('Duplicate: ' + record.id);
            console.log('Duplicate', record);
        },
    }

}
</script>