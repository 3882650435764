<template>
    <Card>

        <div class="flex flex-col gap-4 w-full">

            <section-title class="mb-0">{{ title }}</section-title>
            
            <!-- Divider -->
            <div class="border-b border-gray-200 -mt-4"></div>

            <!-- Total Energy Reduction -->
            <div class="flex font-bold">
                <p class="mr-2">Total Annual Energy Reduction:</p>
                <p class="text-orange-700">{{ totalEnergyReduction }} GJ</p>
            </div>

            <!-- Values -->
            <div class="grid grid-cols-2 md:grid-cols-4 gap-2 w-full">
                <!-- Cost -->
                <div class="flex flex-col">
                    <p class="flex flex-1 text-xs lg:text-sm">Upgrade Cost</p>
                    <p class="text-orange-700">${{ Math.round(totalCost/100).toLocaleString() }}</p>
                </div>   
                <!-- Rebates (Incentives) -->
                <div class="flex flex-col">
                    <p class="flex flex-1 text-xs lg:text-sm">Rebates</p>
                    <p class="text-orange-700">${{ totalIncentiveAmount > 0 ? Math.round(totalIncentiveAmount/100).toLocaleString() : 0}}</p>
                </div>

                <!-- Annual Savings -->
                <div class="flex flex-col">
                    <p class="flex flex-1 text-xs lg:text-sm">Annual Savings</p>
                    <p class="text-orange-700">${{ totalAnnualSavingsAmount > 0 ? Math.round(totalAnnualSavingsAmount/100).toLocaleString() : 0}}</p>
                </div>

                <!-- Payback Period-->
                <!-- <div class="flex flex-col">
                    <p class="flex flex-1 text-xs lg:text-sm">Avg Payback</p>
                    <p class="text-orange-700">{{ averagePaybackPeriod > 0 ? `${Number(averagePaybackPeriod).toFixed(1)} years` : 'Immediate' }}</p>
                </div> -->
            </div>
            
            <!-- Paras -->
            <div class="text-sm">
                <p class="mb-2">Based on your EnerGuide Report and some project cost estimations, we can start to see what some of your best opportunities are to improve your home's energy efficiency.</p>
                <p>Every home is unique and ony with qualified quotes will we know the exact figures for your home.</p>
            </div>


             <!-- Payment Section -->
            <div v-if="paymentLink" class="">
                <h3 class="font-bold mb-6">Let Us Take It From Here</h3>
                <p class="mb-4">For a flat fee of $500, we'll manage the entire project from here, including all planning, managing quotes from trades, and a free EnerGuide Evaluation. <router-link class="link" :to="paymentLink">Pay now</router-link>.</p>

            </div>
        </div> 
    </Card>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    props: {
        project: {
            type: Object,
            required: true,
            default: () => {}
        },
        title: {
            type: String,
            default: "Expected Savings"
        },
        paymentLink: {
            type: String,
            default: ''
        }
    },
    methods: {
        calculateIncentiveTypeAmount(incentives)
        {
            let uniqueIncentives = incentives.filter((incentive, index, self) =>
                index === self.findIndex((t) => (
                    t.id === incentive.id
                ))
            );

            let incentivesTotal = uniqueIncentives.reduce((total, incentive) => {
                return total + incentive.amount;
            }, 0);

            return incentivesTotal;
        }
    },
    computed: {
        ...mapGetters({
            // job: 'projects/getJob',
        }),
        totalCost() {
            return this.project?.jobs?.reduce((total, job) => {
                return total + job.cost;
            }, 0) || 0;
        },
        /**
         * Total incentive amount for job.
         */
         totalIncentiveAmount()
        {
            // Grants
            let grants = this.project?.jobs?.map((job) => job.grants).flat();
            let grantsTotal = this.calculateIncentiveTypeAmount(grants)

            // Rebates
            let rebates = this.project?.jobs?.map((job) => job.rebates).flat();
            let rebatesTotal = this.calculateIncentiveTypeAmount(rebates)
            
            return grantsTotal + rebatesTotal;
        },
        totalAnnualSavingsAmount()
        {
            return this.project?.jobs?.reduce((total, job) => {
                return total + job.annual_cost_savings;
            }, 0) || 0;
        },

        /**
        * Payback period for job.
        */
        totalPaybackPeriod()
        {
            return this.project?.jobs?.reduce((total, job) => {
                return total + (job.cost / (job.annual_cost_savings - this.totalIncentiveAmount));
            }, 0) || 0;

            // return this.job.cost / (this.job.annual_cost_savings - this.totalIncentiveAmount);
        },
        // averagePaybackPeriod()
        // {
        //     this.project?.jobs?.forEach((job) => {

        //         let remainingCosts = job.cost / (job.annual_cost_savings - job..totalIncentiveAmount);

        //         console.log(remainingCosts);

        //         // console.log(job.cost / (job.annual_cost_savings - this.totalIncentiveAmount));
        //     });


        //     let totalCost = this.project?.jobs?.reduce((acc, job) => {
        //         return acc + (job.cost / (job.annual_cost_savings - this.totalIncentiveAmount));
        //     }, 0);

        //     let paybackPeriod = totalCost / this.project?.jobs?.length || 0;
        //     return paybackPeriod;
        // },
        totalEnergyReduction()
        {
            return this.project?.jobs?.reduce((total, job) => {
                return total + job.gigajoules_saved_per_year;
            }, 0) || 0;
        }
    },
}
</script>

<style>

</style>