<template>
    <div>
        <!-- NEW -->
        <RecordListCard title="Appliances" :records="records" :sort-by="[]" :filter-by="[]">

            <!-- Title -->
            <template v-slot:title-actions="slotProps">
                <ActionButtonStack>
                    <template v-slot:actions>
                        <!-- <ActionButton text="Add" @click="addRecord"><v-icon name="plus" class="fill-current"></v-icon></ActionButton> -->
                    </template>
                    <template v-slot:important-actions>
                        <!-- <RecordListActionButton important text="Add" @click="addRecord"><v-icon name="plus" class="fill-current"></v-icon></RecordListActionButton> -->
                    </template>
                </ActionButtonStack>
            </template>

            <!-- List Header -->
            <template v-slot:header="slotProps">
                <RecordListItem header>
                    <div class="flex items-center font-bold">
                        <!-- <RecordListSortableHeaderItem class="flex-1" field="name" @click="slotProps.list.sortRecords('name')">Name</RecordListSortableHeaderItem> -->
                        <div class="flex-1">Type</div>
                        <div class="hidden md:block w-1/4 md:w-1/2">Age</div>
                    </div>
                </RecordListItem>
            </template>

            <!-- Records -->
            <template v-slot:record="slotProps">
                <RecordListItem :visible="slotProps.record.deleted_at == null">

                    <!-- Details  -->
                    <div class="flex items-center">
                        <div class="flex flex-1 items-center">
                            <div class="mr-2">
                                <img :src="image(slotProps.record.type.image_url)" class="w-6 h-6 mr-2 inline-block"/>
                            </div>
                            <a href="#" class="hover:underline" @click.prevent="viewRecord(slotProps.record)">{{ slotProps.record.type.name }}</a>
                        </div>
                        <div v-if="slotProps.record.year" class="hidden md:block w-1/4 md:w-1/2">{{  (new Date()).getFullYear() - slotProps.record.year }} years</div>
                    </div>

                    <!-- Important Actions -->
                    <template v-slot:important-actions>
                        <RecordListActionButton important text="Edit" @click="editRecord(slotProps.record)"><v-icon name="pen" class="fill-current"></v-icon></RecordListActionButton>
                    </template>

                    <!-- Other Actions -->
                    <template v-slot:actions>
                        <!-- <RecordListActionButton text="Delete" @click="deleteRecord(slotProps.slotProps.record)"><v-icon name="trash" class="fill-current"></v-icon></RecordListActionButton> -->
                    </template>

                </RecordListItem>
            </template>
        </RecordListCard>

        <!-- Modals -->
        <CreateApplianceModal/>
        <UpdateApplianceModal/>
        <ApplianceModal/>
    </div>

</template>

<script>
// import RecordList from '../../../Components/RecordList/RecordList.js';
import RecordListCard from '../../../Components/RecordList/RecordList.vue';
import RecordListItem from '../../../Components/RecordList/RecordListItem.vue';
import RecordListActionButton from '../../../Components/RecordList/RecordListActionButton.vue';
import CreateApplianceModal from './CreateApplianceModal.vue';
import UpdateApplianceModal from './UpdateApplianceModal.vue';
import ApplianceModal from './ApplianceModal.vue';
import { mapGetters } from 'vuex';

export default {
    components: {
        RecordListCard,
        RecordListItem,
        RecordListActionButton,
        CreateApplianceModal,
        UpdateApplianceModal,
        ApplianceModal
    },
    props: {
        records: {
            type: Array,
            required: true,
            default: () => []
        },
    },
    data() {
        return {
            //
        }
    },
    computed: {
        ...mapGetters({
            appliance: 'appliances/getAppliance'
        })
    },
    methods: {
        async editRecord(record)
        {
            this.$store.commit('appliances/setAppliance', record);
            this.$modal.show('update-appliance-modal');
        },
        viewRecord(record)
        {
            this.$store.commit('appliances/setAppliance', record);
            this.$modal.show('appliance-modal')
        },
        addRecord()
        {
            this.$modal.show('create-appliance-modal');
        },
        deleteRecord(record)
        {
            alert('Delete: ' + record.id);
            console.log('Delete', record);
        },
        duplicateRecord(record)
        {
            alert('Duplicate: ' + record.id);
            console.log('Duplicate', record);
        },
    }

}
</script>