<template>
  <div class="md:flex md:flex-col bg-white rounded shadow-md p-4">
        <div class="flex justify-between items-start mb-9">
            <!-- Title -->
            <h3 class="font-bold">{{ title }}</h3>
            <!-- Price -->
            <p>{{ price }}</p>
        </div>

        <div class="mb-6 text-sm text-gray-800 md:flex-1">
            <!-- Text -->
            <p v-for="(paragraph, index) in description" :key="index" class="mb-3">{{ paragraph }}</p>
        </div>

        <div>
            <!-- CTA -->
            <SquareButton class="plan-signup-link" @click="$emit('cta-click')" :disabled="disabled">{{ cta }}</SquareButton>
        </div>
  </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        price: {
            type: String,
            required: true
        },
        description: {
            type: Array,
            required: true
        },
        cta: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style>

</style>