import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
// import Home from '@views/Home.vue'
import Home from '@views/Home/Home.vue'
import Properties from '@views/User/Properties'
import Property from '@property/Views/Property'
import Room from '@property/Views/Room'
import UserSettings from '../User/Views/UserSettings'
import CreatePropertyView from '@property/Views/CreatePropertyView'

import { removeClarityScript } from '../Services/Clarity'

Vue.use(VueRouter)

const routes = [
  // Home  - Redirects to properties as main user screen.
  {
    path: '/',
    name: 'home',
    redirect: '/properties',
  },

  // Onboarding
  {
    path: '/retrofit/onboarding',
    name: 'retrofit.onboarding',
    component: () => import('../Retrofit/Views/Onboarding.vue')
  },

  // View Retrofit Report
  {
    path: '/retrofit_report/:id',
    name: 'retrofit_report',
    component: () => import('../Retrofit/Views/RetrofitReport.vue'),
    meta: {
      requiresAuth: true
    },
  },

  // View Retrofit Report via a Shared token
  // http://0.0.0.0:80/retrofit/report/view/10b73197972…b332555242a32d7524330e9632ce6e974aba1728476dea112
  {
    path: '/retrofit/report/view/:token',
    name: 'retrofit_report.view',
    component: () => import('../Retrofit/Views/RetrofitReport.vue'),
  },

  
  // Privacy Policy
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@views/Privacy.vue')
  },
  // {
  //   path: '/marketplace/:id',
  //   name: 'listing',
  //   component: () => import('../Marketplace/Views/Listing')
  // },
  // {
  //   path: '/marketplace',
  //   name: 'marketplace',
  //   component: () => import('../Marketplace/Views/Marketplace')
  // },

  // Login
  {
    path: '/signin',
    name: 'signin',
    component: () => import('@views/Main.vue'),
    children: [
      {
        path: '/signin',
        // name: 'signin',
        component: () => import('../Auth/Views/Login.vue'),
      },
    ],
  },

  // Reset Password
  {
    name: 'users.reset-password',
    path: '/reset-password/:token',
    component: () => import('../User/Views/ResetPassword.vue'),
  },

  // Complete Registration
  {
    name: 'users.complete-registration',
    path: '/complete-registration/:token',
    component: () => import('../User/Views/CompleteRegistration.vue'),
  },

  // Register Routes
  {
    name: 'register',
    path: '/register',
    component: () => import('../Auth/Views/Register.vue'),
  },

  // User Routes
  {
    path: '/users/:id',
    name: 'Main',
    component: () => import('@views/Main.vue'),
    children: [
      // Settings
      {
        name: 'users.settings',
        path: '/users/:id/settings',
        component: UserSettings,
        meta: {
          requiresAuth: true,
        },
      },
      // Properties
      {
        // All Properties
        name: 'properties.index',
        path: '/properties',
        component: Properties,
        beforeEnter: (to, from, next) => {
          if(store.getters['auth/isAuthenticated']) 
          {
            let user = store.getters['auth/getUser'];
            store.dispatch('users/getProperties', user.id).then(() => {
              let properties = store.getters['users/getProperties'];
              next(`/properties/${properties[0]?.id}`)
            })
          } else {
            next('/signin');
          }

        }
      },

      // View Property Energy Profile
      {
        name: 'properties.energy_profile.view',
        path: '/properties/:id/energy-profile',
        component: () => import('../Property/Views/EnergyProfile.vue'),
        meta: {
          requiresAuth: true,
          requiresAdmin: true
        },
      },
      
      // View Property 
      {
        name: 'properties.view',
        path: '/properties/:id',
        component: () => import('../Property/Views/Property.vue'),
        meta: {
          requiresAuth: true,
        },
      },


      // Create Property
      {
        name: 'properties.create',
        path: '/properties/create',
        component: CreatePropertyView,
      },
      
      // View Room
      {
        name: 'rooms.view',
        path: '/rooms/:id',
        component: Room,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },  
      // View Project
      {
        name: 'projects.view',
        path: '/projects/:id',
        component: () => import('../Projects/Views/Project.vue'),
        props: true,
        meta: {
          requiresAuth: true,
        },
      },  
      {
        name: 'projects.payment.view',
        path: '/projects/:id/payment',
        component: () => import('../Projects/Views/ProjectPayment.vue'),
        props: true,
        meta: {
          requiresAuth: true,
        },
      },  
    ],
  },

  {
    path: '/admin',
    component: () => import('@views/Admin/Admin.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    },
    children: [
      {
        // A Property
        name: 'admin.dashboard',
        path: '/admin',
        component: () => import('../Admin/Views/Admin.vue'),
        meta: {
          requiresAuth: true,
          requiresAdmin: true
        },
      },
      {
        // All Properties
        name: 'admin.properties',
        path: '/admin/properties',
        component: () => import('../Admin/Views/Properties.vue'),
        meta: {
          requiresAuth: true,
          requiresAdmin: true
        },
      },
      {
        // A Project
        name: 'admin.projects',
        path: '/admin/projects',
        component: () => import('../Admin/Views/Projects.vue'),
        meta: {
          requiresAuth: true,
          requiresAdmin: true
        },
      },
      // Project Plans
      {
        name: 'admin.project_plans.index',
        path: '/admin/project_plans',
        component: () => import('../ProjectPlan/Views/ProjectPlans.vue'),
        meta: {
          requiresAuth: true,
          requiresAdmin: true
        },
      },
      // Project Plan
      {
        name: 'admin.project_plans.show',
        path: '/admin/project_plans/:id',
        component: () => import('../ProjectPlan/Views/ProjectPlan.vue'),
        meta: {
          requiresAuth: true,
          requiresAdmin: true
        },
      },
      {
        // A Property
        name: 'admin.properties.show',
        path: '/admin/properties/:id',
        component: () => import('../Admin/Views/Property.vue'),
        meta: {
          requiresAuth: true,
          requiresAdmin: true
        },
      },
      {
        // Retrofit Programs
        name: 'retrofit_programs.index',
        path: '/admin/programs',
        component: () => import('../RetrofitProgram/Views/RetrofitPrograms.vue'),
        meta: {
          requiresAuth: true,
          requiresAdmin: true
        },
      },
      {
        // Retrofit Program
        name: 'retrofit_programs.show',
        path: '/admin/programs/:id',
        component: () => import('../RetrofitProgram/Views/RetrofitProgram.vue'),
        meta: {
          requiresAuth: true,
          requiresAdmin: true
        },
      },
      {
        // Retrofit Program Tier
        name: 'retrofit_program_tiers.show',
        path: '/admin/retrofit_program_tiers/:id',
        component: () => import('../RetrofitProgram/Views/RetrofitProgramTier.vue'),
        meta: {
          requiresAuth: true,
          requiresAdmin: true
        },
      },
      {
        // Project Job
        name: 'project_jobs.show',
        path: '/jobs/:id',
        component: () => import('../Projects/Views/Job.vue'),
        meta: {
          requiresAuth: true,
          requiresAdmin: true
        },
      },
      {
        // Project Opportiunity
        name: 'project_opportunities.show',
        path: '/opportunities/:id',
        component: () => import('../Projects/Views/Opportunity.vue'),
        meta: {
          requiresAuth: true,
          requiresAdmin: true
        },
      },
      {
        // Incentives
        name: 'incentives.index',
        path: '/admin/incentives',
        component: () => import('../Projects/Views/Incentives.vue'),
        meta: {
          requiresAuth: true,
          requiresAdmin: true
        },
      },
      {
        name: 'admin.users.index',
        path: '/admin/users',
        component: () => import('../User/Views/Users.vue'),
        meta: {
          requiresAuth: true,
          requiresAdmin: true
        },
      },
      {
        name: 'users.show',
        path: '/admin/users/:id',
        component: () => import('../User/Views/User.vue'),
        meta: {
          requiresAuth: true,
          requiresAdmin: true
        },
      },
      {
        name: 'admin.countries.index',
        path: '/admin/countries',
        component: () => import('../Location/Views/Countries.vue'),
        meta: {
          requiresAuth: true,
          requiresAdmin: true
        },
      },
      {
        name: 'admin.countries.show',
        path: '/admin/countries/:id',
        component: () => import('../Location/Views/Country.vue'),
        meta: {
          requiresAuth: true,
          requiresAdmin: true
        },
      },
      {
        name: 'admin.states.show',
        path: '/admin/states/:id',
        component: () => import('../Location/Views/State.vue'),
        meta: {
          requiresAuth: true,
          requiresAdmin: true
        },
      },
      {
        name: 'admin.cities.show',
        path: '/admin/cities/:id',
        component: () => import('../Location/Views/City.vue'),
        meta: {
          requiresAuth: true,
          requiresAdmin: true
        },
      },
      // Service Companies
      {
        name: 'admin.service_companies.index',
        path: '/admin/service_companies',
        component: () => import('../ServiceCompany/Views/ServiceCompanies.vue'),
        meta: {
          requiresAuth: true,
          requiresAdmin: true
        },
      },
      {
        name: 'admin.service_companies.show',
        path: '/admin/service_companies/:id',
        component: () => import('../ServiceCompany/Views/ServiceCompany.vue'),
        meta: {
          requiresAuth: true,
          requiresAdmin: true
        },
      },

    ]
  },

  // Default Route
  { 
    path: "*",
    redirect: '/'
  },
]

// Configure Router instance
const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0, behavior: 'smooth' }
  }
});

const clarityRouteWhitelist = [
  'retrofit.onboarding',
  'properties.view',
  // 'projects.payment.view'
]

const clarityRouteBlacklist = [
  //
]



router.beforeEach((to, from, next) => {
  
  let isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
  let isAdmin = localStorage.getItem('isAdmin') === 'true';

  // if route requires authentication - requiresAuth is true
  if (to.matched.some((record) => record.meta.requiresAuth)) {

    !isAuthenticated ? next({
      path: '/signin',
      query: { redirect: to.fullPath }
    }) : next();
  }

  // if route requires admin - requiresAdmin is true
  if (to.matched.some((record) => record.meta.requiresAdmin)) { 
    !isAdmin ? next({
      path: '/',
      query: { redirect: to.fullPath }
    }) : next();
  }


  // Clarity approved views
  // if (from.name === 'retrofit.onboarding' && to.name !== 'retrofit.onboarding') {
  //   removeClarityScript();
  // }

  // if(clarityRouteWhitelist.includes(from.name) && !clarityRouteWhitelist.includes(to.name)) {
  //   removeClarityScript();
  // }

  if(!clarityRouteWhitelist.includes(to.name) || clarityRouteBlacklist.includes(to.name)) {
    removeClarityScript();
  }

  next();
});

export default router
