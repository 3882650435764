<template>
    <Modal :name="name" width="600" height="auto" @before-open="beforeOpen" :scrollable="true">
        <div class="p-6 pt-16 md:p-16">
            <!-- Update Form -->
            <div>
                <!-- Title -->
                <SectionTitle>Update Property</SectionTitle>

                <!-- Update Info Form -->

                <div id="update-property-form" aria-type="form">
                    <!-- Address -->
                    <!-- Address + Unit # -->
                    <FormRow class="">
                        <div class="w-full mr-4 mb-4">
                            <Label for="street">Street</Label>
                            <TextInput name="street" v-model="form.street" class="" placeholder=""/>
                            <Errors v-if="form.errors.has('street')">{{form.errors.first('street')}}</Errors>
                        </div>
                        <div class="w-full md:w-1/2">
                            <Label for="unit_number">Unit/Apt/Suite #</Label>
                            <TextInput name="unit_number" v-model="form.unit_number" class="" placeholder=""/>
                            <Errors v-if="form.errors.has('unit_number')">{{form.errors.first('unit_number')}}</Errors>
                        </div>
                    </FormRow>

                    <!--Country + State -->
                    <FormRow class="md:flex">
                        <!-- <div class="w-full md:w-1/2 mr-4 mb-4 md:mb-0">
                        <Label for="country">Country</Label>
                        <TextInput name="country" v-model="form.country" class="" placeholder=""/>
                        <Errors v-if="form.errors.has('country')">{{form.errors.first('country')}}</Errors>
                        </div>
                        <div class="w-full md:w-1/2">
                        <Label for="state">Province</Label>
                        <TextInput name="state" v-model="form.state" class="" placeholder=""/>
                        <Errors v-if="form.errors.has('state')">{{form.errors.first('state')}}</Errors>
                        </div> -->
                        <div class="w-full md:w-1/2 mr-4 mb-4 md:mb-0">
                            <Label for="country_id">Country</Label>
                            <SelectInput name="country_id" v-model="form.country_id" placeholder="">
                                <option v-for="(country, index) in countries" :value="country.id" :key="index">{{country.name}}</option>
                            </SelectInput>
                            <Errors v-if="form.errors.has('country_id')">{{form.errors.first('country_id')}}</Errors>
                            
                        </div>

                        <div class="w-full md:w-1/2">
                            <Label for="state_id">Province</Label>
                            <SelectInput name="state_id" v-model="form.state_id" placeholder="">
                                <option v-for="(state, index) in states" :value="state.id" :key="index">{{state.name}}</option>
                            </SelectInput>
                            <Errors v-if="form.errors.has('state_id')">{{form.errors.first('state_id')}}</Errors>
                        </div>
                    </FormRow>

                    <!--City -->
                    <FormRow class="md:flex">
                        <div class="w-full md:w-1/2 mr-4 mb-4 md:mb-0">
                            <Label for="city">City</Label>
                            <TextInput name="city" v-model="form.city" class="" placeholder=""/>
                            <Errors v-if="form.errors.has('city')">{{form.errors.first('city')}}</Errors>
                        </div>
                        <div class="w-full md:w-1/2">
                            <Label for="postal_code">Postal Code</Label>
                            <TextInput name="postal_code" v-model="form.postal_code" class="" placeholder=""/>
                            <Errors v-if="form.errors.has('postal_code')">{{form.errors.first('postal_code')}}</Errors>
                        </div>
                    </FormRow>

                    <!-- Property Type -->
                    <FormRow class="w-full md:w-1/2 mb-4">
                        <Label for="property_type_id">Property Type</Label>
                        <SelectInput name="property_type_id" v-model="form.property_type_id" placeholder="">
                            <option v-for="typee in property_types" :value="typee.id" :key="typee.id">{{typee.name}}</option>
                        </SelectInput>
                        <Errors v-if="form.errors.has('property_type_id')">{{form.errors.first('property_type_id')}}</Errors>
                    </FormRow>

                    <!-- Build Year -->
                    <FormRow class="w-full md:w-1/2 mb-4">
                        <Label for="year_built">Build Year</Label>
                        <TextInput name="year_built" v-model="form.year_built" class="" placeholder="1974"/>
                        <Errors v-if="form.errors.has('year_built')">{{form.errors.first('year_built')}}</Errors>
                    </FormRow>

                    <!-- Roof Type -->
                    <FormRow class="md:w-1/2 mb-8">
                        <Label for="roof_type">Roof Type (Optional)</Label>
                        <SelectInput name="roof_type" v-model="form.roof_type" class="2/3">
                            <option v-for="(roof_type, index) in roof_types" :key="index" :value="roof_type">{{roof_type}}</option>
                            <!-- <option value="Other">Other</option> -->
                        </SelectInput>
                        <Errors v-if="form.errors.has('roof_type')">{{form.errors.first('roof_type')}}</Errors>
                    </FormRow>

                    <!-- ACTIONS -->
                    <FormRow class="mb-12">
                        <SquareButton context="primary" class="mr-2" @click="submit">Save</SquareButton>
                        <!-- <SquareButton context="gray" @click="form.previousSection()">Back</SquareButton> -->
                    </FormRow>      

                </div>


                <!-- Update Banner Photo Form -->
                <!-- <div>
                    <FormRow class="">
                        <div class="w-full mr-4 mb-4">
                            <Label for="file">Banner Image</Label>
                            <FileInput name="file" @change="bannerForm.addFile($event)"/>
                            <Errors v-if="bannerForm.errors.has('file')">{{bannerForm.errors.first('file')}}</Errors>
                        </div>
                        <div class="mb-4 md:mb-0">
                            <span class="text-sm">1 MB file size limit</span>
                        </div>
                    </FormRow> -->

                    <!-- ACTIONS -->
                    <!-- <FormRow class="mb-12">
                        <SquareButton context="primary" class="mr-2" @click="updateBannerImage">Update</SquareButton>
                        <SquareButton context="gray" @click="$modal.hide(name)">Close</SquareButton>
                    </FormRow>  
                </div> -->

            <!-- Delete Form -->
            <!-- <div>
                <div class="p-4 rounded border border-red-700 border-dashed">
                    <div class="flex text-red-700 hover:text-red-900 cursor-pointer" @click="delete_expanded = !delete_expanded">
                        <div class="mr-4">
                            <v-icon class="fill-current" :name="delete_expanded ? 'chevron-up' : 'chevron-down'"></v-icon>
                        </div>
                        <div>
                            Delete
                        </div>
                    </div>
                    
                    <div class="mt-8" v-show="delete_expanded">
                        <div class="mb-6 text-red-900">
                            Do you wish do delete your property? This cannot be undone without contacting Paddio directly.
                        </div>

                        <FormRow>
                            <square-button context="gray" class="mr-2" @click="deleteProperty">Delete</square-button>
                        </FormRow>

                    </div>
                </div>
            </div> -->
        </div>
        </div>
    </Modal>
    
</template>

<script>
import Modal from '@components/Modal'
import SectionTitle from '@components/SectionTitle'
import Form from '@lukejm/form-js'
import {formDefaults} from '@property/Forms/UpdatePropertyForm.js'
import {formDefaults as bannerFormDefaults} from '@property/Forms/UpdatePropertyBannerImageForm.js'
import SquareButton from '@components/Buttons/SquareButton.vue'
import { mapGetters } from 'vuex'

export default {
    components: {
        Modal,
        SectionTitle,
        SquareButton,
    },
    computed: {
        ...mapGetters({
            property: 'properties/getCurrentProperty',
            property_types: 'properties/getPropertyTypes',
            roof_types: 'properties/getRoofTypes',
            countries: 'addresses/getCountries',
            states: 'addresses/getStates'
        })
    },
    data()
    {
        return {
            name: 'update-property-modal',
            form: new Form(formDefaults),
            bannerForm: new Form(bannerFormDefaults),
            delete_expanded: false,
        }
    },
    methods:
    {
        async beforeOpen(event) {

             // Fill property fields
            this.form.street = this.property.address.street
            this.form.unit_number = this.property.address.unit_number
            this.form.country_id = this.property.address.country.id
            this.form.city = this.property.address.city.name
            this.form.postal_code = this.property.address.postal_code
            this.form.roof_type = this.property.roof_type
            this.form.year_built = this.property.year_built


            // if(this.property.property_type) {
                this.form.property_type_id = this.property.property_type.id
            // }

            // Fill State
            await this.$store.dispatch('addresses/setStatesForCountry', this.property.address.country.id);
            this.form.state_id = this.property.address.state.id


        },
        async deleteProperty()
        {
        //     await this.$store.dispatch('appliances/deleteProperty', this.appliance)
        //         .then(() => {

        //             // TODO - update state.property.appliances
        //             // TODO - update state.room.appliances
        //             this.$modal.hide(this.name)
        //             // this.$modal.show('appliance-modal', this.$store.getters['appliances/getAppliance'])

        //             this.$notify({
        //                 title: 'Success',
        //                 text: `The appliances has been deleted.`,
        //                 type: 'success'
        //             });
        //             }).catch(error => {
        //             console.error(error);
        //             this.$notify({
        //                 title: 'Error',
        //                 text: error.message,
        //                 type: 'error'
        //             })
        //         });      
        },
        // updateBannerImage()
        // {
        //     this.bannerForm.post(`/api/v1/properties/${this.property.id}/banner`)
        //     // this.$store.dispatch(`properties/updateBannerImage`, this.bannerForm.data())
        //         .then((response) => {
        //             this.$store.dispatch('properties/updateBannerImage', response.data.path)

        //             document.getElementById('banner-image').src = `/${response.data.path}?t=${new Date().getTime()}`;

        //             this.$notify({
        //                 title: 'Success',
        //                 text: `Your property has been updated.`,
        //                 type: 'success'
        //             });

        //         }).catch(error => {
        //             console.error(error);

        //             this.$notify({
        //                 title: 'Error',
        //                 text: error.message,
        //                 type: 'error'
        //             })
        //         });
        // },
        async submit()
        {
            await this.$store.dispatch('properties/updateProperty', this.form.data())
                .then(() => {
                    this.$notify({
                        title: 'Success',
                        text: `Your property has been updated.`,
                        type: 'success'
                    });

                    // this.$router.go(); // Reload page
                }).catch(error => {
                    console.error(error);

                    this.$notify({
                        title: 'Error',
                        text: error.message,
                        type: 'error'
                    })
                });
        }
    },
    async created() {
        await this.$store.dispatch('properties/getPropertyTypes');
        await this.$store.dispatch('properties/getRoofTypes');

        // Get Countries
        await this.$store.dispatch('addresses/setCountries');    
    },
}
</script>
