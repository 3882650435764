<template>
  <form class="flex flex-col">
    <!-- {{ form.first_name }} {{ form.last_name }} [PROV: {{ form.province_id }}] - {{ dirty ? "DIRTY" : "CLEAN" }} -->
    <!-- <Field label="First Name" name="first_name" v-model="form.first_name" placeholder="Sally"></Field>
    <Field label="Last Name" name="last_name" v-model="form.last_name" placeholder="Jones"></Field>
    <Field type="select" label="Province" name="state_id" v-model="form.state_id" :options="provinces" placeholder="Select a province"></Field> -->

    <!-- <Field label="Street" name="street" v-model="form.street" placeholder=""></Field> -->
<!-- 
    {{ form }} <br>
    {{ dirty ? "DIRTY" : "CLEAN" }} -->
    <slot></slot>

  </form>
</template>
 
<script>
import Field from './Field.vue';

export default {
    components: {
        Field,
    },
    // props: {
    //     form: {
    //         type: Object,
    //         required: true,
    //     },
    // },
    data() {
        return {
            // form: {
            //     first_name: 'Jane',
            //     last_name: 'Doe',
            //     state_id: 1,
            // },
            dirty: false,
            // Used for testing
            provinces: [
                {
                    name: 'Alberta',
                    value: 1,
                },
                {
                    name: 'British Columbia',
                    value: 2,
                },
                {
                    name: 'Manitoba',
                    value: 3,
                },
                {
                    name: 'New Brunswick',
                    value: 4,
                },
                {
                    name: 'Newfoundland and Labrador',
                    value: 5,
                },
                {
                    name: 'Northwest Territories',
                    value: 6,
                },
                {
                    name: 'Nova Scotia',
                    value: 7,
                },
                {
                    name: 'Nunavut',
                    value: 8,
                },
                {
                    name: 'Ontario',
                    value: 9,
                },
                {
                    name: 'Prince Edward Island',
                    value: 10,
                },
                {
                    name: 'Quebec',
                    value: 11,
                },
                {
                    name: 'Saskatchewan',
                    value: 12,
                },
                {
                    name: 'Yukon',
                    value: 13,
                },
            ],
        };
    },
    methods: {
        
    },
    watch: {
        form: {
            handler() {
                this.dirty = true;
            },
            deep: true,
        },
    },
}
</script>

<style>

</style>