<template>
  <div class="relative w-full">

    <!-- Sections -->
    <div class="flex w-full gap-x-1">

        <!-- Section -->
        <div v-for="(section, index) in getSections" :key="index" class="flex flex-col" :class="[`w-1/${getSections.length}`]">

            <!-- {{ section }} -->
            <!-- Indicators -->
            <div class="relative h-24 mb-2 md:mb-5">
                <div v-for="(indicator, indicatorIndex) in indicators" :key="indicatorIndex">

                    <!-- Indicator -->
                    <div v-if="index == indicator?.section" class="indicator absolute text-center cursor-pointer" :style="{'left': `calc(${indicator.left} - 2.5rem)`, 'z-index': section.zIndex}">
                        <div class="group flex flex-col items-center">
                            <!-- Label -->
                            <div class="bg-white px-2">{{ indicator.type }}</div>

                            <!-- Marker -->
                            <div 
                                class="w-16 h-16 md:w-20 md:h-20 flex items-center justify-center rounded-full border-4 bg-white group-hover:bg-gray-50 bottom-0" 
                                :style="{'border-color': section.color}"
                            >
                                <span class="text-lg md:text-2xl">{{ indicator.value == 0 ? '?' : indicator.value }}</span>
                            </div>
                            
                            <!-- Arrow -->  
                            <v-icon name="caret-down" class="block fill-current" scale="2.8" :style="{'color': section.color, 'margin-top': '-1.3rem'}"></v-icon>

                        </div>
                    </div>
                </div>
  
            </div>

            <!-- Meter -->
            <div class="flex flex-col flex-1 py-3 text-center justify-between md:justify-start" :style="{'background-color': section.color}">
                <!-- Values -->
                <span class="block text-sm md:text-xl mb-1">{{ section?.lower_value }} {{ section?.upper_value == 250 ? '+' : `- ${section?.upper_value}` }}</span>
                <!-- Name -->
                <span class="block text-sm">{{ section?.name }}</span>
            </div>            
        </div>
    </div>
  </div>
</template>

<script>

export let energyMeterSections = [
    // {
    //     name: 'Very Efficient',
    //     color: '#86C35E',
    //     // lower_value: 0,
    //     // upper_value: 25,
    // },
    {
        name: 'Efficient',
        color: '#A1D680',
        // lower_value: 26,
        // upper_value: 50,
    },
    {
        name: 'Moderate',
        color: '#FDECB6',
        // lower_value: 51,
        // upper_value: 100,
    },
    {
        name: 'Inefficient',
        color: '#EFB2B2',
        // lower_value: 101,
        // upper_value: 150,
    },
    {
        name: 'Very Inefficient',
        color: '#F28F8F',
        // lower_value: 151,
        // upper_value: 250,
    },

];

export default {
    data() {
        return {
            maxRating: 400,
            sections: energyMeterSections,
            // indicators: [],
            potentialRatingIndicator: {},
            currentRatingIndicator: {},
            ratingSectionIndex: 0,
        }
    },
    props: {
        rating: {
            type: Number,
            required: true,
            default: 0,
        },
        // averageRating: {
        //     type: Number,
        //     required: true,
        //     default: 0,
        // },
        potentialRating: {
            type: Number,
            // required: true,
            default: 20,
        },
    },
    computed: {
        indicators() {
            return [
                this.getRatingIndicatorPosition(this.rating, 'Current'),
                this.getRatingIndicatorPosition(this.potentialRating, 'Potential')
            ];
        },
        getSections()
        {
            return this.updateSections();
        },
        
        
    },
    methods: {
        updateSections() {
            let indicatorSpread = this.rating - this.potentialRating;
            let newSections = this.sections;
            let upperBound = (this.rating * 1.1);
            let lowerBound = (this.potentialRating * 0.9);
            let chunkSize = (upperBound-lowerBound)/this.sections.length;

            newSections.forEach((sect, index) => {
                sect.index = index;
                sect.lower_value = Math.round(lowerBound + chunkSize * index);
                sect.upper_value = Math.round(lowerBound + chunkSize * (index+1));

                if(this.rating >= sect.lower_value && this.rating <= sect.upper_value) {
                    this.ratingSectionIndex = index;
                }
            });

            return this.sections = newSections;
        },
        getIndicators() {
            this.currentRatingIndicator = this.getRatingIndicatorPosition(this.rating);
            this.potentialRatingIndicator = this.getRatingIndicatorPosition(this.potentialRating);
        },
        // Set section z-index
        setSectionZIndex(section, rating)
        {
            if (rating >= section.lower_value && rating <= section.upper_value) {

            if(rating == 0) {
                if(type == 'Potential') {
                    section.zIndex = 1
                } else if(type == 'Current') {
                    section.zIndex = 3
                }
            }
            } else {
                // section.index = index;
            }

            return section;
        },
        getRatingIndicatorPosition(rating, type) {
            // the rating and potential rating must exist along the scale bound by the lower and upper values of the sections.  We should first determine which section the rating falls into, then determine the percentage of the way through that section the rating is.  We can then use that percentage to determine the position of the indicator.
            // let section;
            let sections = this.getSections;
            let section = this.sections.find(section => {
                return rating >= section.lower_value && rating <= section.upper_value;
            });

            if(section) {
                let percentage = 0.5;
                let left = (percentage * 100) + '%';

                if(rating > 0) {
                    if(sections?.length == section?.index+1 && rating >= section.upper_value-20) {
                        percentage = 0.8;
                    } else {    
                        percentage = (rating - section.lower_value) / (section.upper_value - section.lower_value)
                    }
                }
                let indicator = {
                    value: rating,
                    left: left,
                    section: section.index,
                    type: type
                }

                return indicator;
            }
            
        }
    },
    created() {

        this.updateSections();

        // define limits
            // - what are sections based upon rating and potential rating?
            // this.createSections();
            // - what are section limits?

        // console.log('this.rating', this.rating);

        // Draw Indicators
        // this.calculateSectionLimits();



    }
}
</script>

<style lang="scss" scoped>
.indicator {
    &:hover {
        z-index: 100;
    }
}

</style>