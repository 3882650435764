<template>
  <div class="flex flex-col rounded border border-neutral-200 p-4 w-full overflow-hidden">
        <!-- Upper -->
        <div class="-mx-4 -mt-4 flex flex-1">
            <div class="header flex flex-col flex-1">

                <!-- Banner -->
                <div class="flex items-center justify-center w-full h-8 mb-8">
                    <!-- best plan -->
                    <div v-if="plan.short_title=='FULL'" class="flex items-center justify-center w-full bg-orange-700 pt-6 pb-2">
                        <div class="uppercase text-white font-bold">best value</div>
                    </div>
                    <!-- Others -->
                    <div></div>
                </div>
            
                <!-- Title -->
                <h2 class="font-bold mb-1 px-4">{{plan.title}}</h2>
                <!-- Description -->
                <p class="text-sm px-4">{{plan.description}}</p>
            </div>
        </div>

        <!-- divider -->
        <div class="border-b border-neutral-200 my-4"></div>

        <!-- Lower -->
            <div>
                <div class="text-center mb-2">
                    <!-- Prices -->
                    <div class="mb-4">
                        <!-- Original Price -->
                        <div class="text-xl">
                            <sup>$</sup><span class="line-through">{{(plan.original_cost/100 ?? 0).toLocaleString()}}</span>
                        </div>
                        <!-- Savings -->
                        <div class="text-xs">({{ (100-(plan.cost/(plan.original_cost/100))*100).toFixed(0) }}% Savings)</div>
                        <!-- Price -->
                        <div class="text-xl font-bold text-orange-700"><sup>$</sup>{{(plan.cost ?? 0).toLocaleString()}}</div>
                    </div>

                    <!-- Subtext -->
                    <div class="text-center text-sm">For those who are curious about energy savings opportunities and doing renovations themselves.</div>
                </div>

                <!-- Disclaimers -->
                <div>
                    <span class="text-xs italic">*EnerGuide Energy Audit fees not included.</span>
                </div>
            </div>
    </div>
</template>

<script>
export default {
    props: {
        plan: {
            type: Object,
            required: true
        }
    }
}
</script>

<style>

</style>