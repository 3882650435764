<template>
    <div>
        <!-- NEW -->
        <RecordList title="Documents" :records="records" :sort-by="['name', 'created_at']" :filter-by="[]">

            <!-- Title -->
            <template v-slot:title-actions="slotProps">
                <ActionButtonStack>
                    <template v-slot:actions>
                        <!-- <ActionButton text="Add" @click="addRecord"><v-icon name="plus" class="fill-current"></v-icon></ActionButton> -->
                        <!-- <ActionButton :text="slotProps.list.withDeleted ? `Hide Deleted` : `Show Deleted`" @click="slotProps.list.toggleDeleted()"><v-icon name="eye-slash" class="fill-current"></v-icon></ActionButton> -->
                    </template>
                    <template v-slot:important-actions>
                        <RecordListActionButton important text="Add Document" @click="addRecord"><v-icon name="plus" class="fill-current"></v-icon></RecordListActionButton>
                    </template>
                </ActionButtonStack>
            </template>

            <!-- List Header -->
            <template v-slot:header="slotProps">
                <RecordListItem header>
                    <div class="flex items-center font-bold">
                        <RecordListSortableHeaderItem class="flex-1" field="name" @click="slotProps.list.sortRecords('name')">Name</RecordListSortableHeaderItem>
                        <RecordListSortableHeaderItem class="w-1/5 text-center items-center" field="created_at" justify="center" @click="slotProps.list.sortRecords('created_at')">Created</RecordListSortableHeaderItem>
                    </div>
                </RecordListItem>
            </template>

            <!-- Records -->
            <template v-slot:record="slotProps">
                <RecordListItem :visible="slotProps.record.deleted_at == null">

                    <!-- Details  -->
                    <div class="flex items-center">
                        <div class="flex flex-1 items-center">
                            <a :href="`/downloads?path=${slotProps.record.filepath}`" class="hover:underline" @click.prevent="viewRecord(slotProps.record)">{{ slotProps.record.name }}</a>
                        </div>

                        <div class="w-1/5 text-center items-center">{{new Date(slotProps.record.created_at).toLocaleDateString() }}</div>
                    </div>

                    <!-- Important Actions -->
                    <template v-slot:important-actions>
                        <!-- <RecordListActionButton important text="Edit" @click="editRecord(slotProps.slotProps.slotProps.record)"><v-icon name="pen" class="fill-current"></v-icon></RecordListActionButton> -->
                    </template>

                    <!-- Other Actions -->
                    <template v-slot:actions>
                        <!-- <RecordListActionButton v-if="slotProps.record?.name == 'Homeowner Information Sheet'" text="Generate Energy Report" @click="generateEnergyReport(slotProps.record)"><v-icon name="chart-bar" class="fill-current"></v-icon></RecordListActionButton> -->
                        <!-- <RecordListActionButton text="Delete" @click="deleteRecord(slotProps.record)"><v-icon name="trash" class="fill-current"></v-icon></RecordListActionButton> -->
                    </template>

                </RecordListItem>
            </template>
        </RecordList>

        <AddDocumentModal :name="`add-document-modal-${documentable}`"></AddDocumentModal>
    </div>
</template>

<script>
import RecordList from '../../Components/RecordList/RecordList.vue';
import RecordListItem from '../../Components/RecordList/RecordListItem.vue';
import RecordListActionButton from '../../Components/RecordList/RecordListActionButton.vue';
import { mapGetters } from 'vuex';
import RecordListSortableHeaderItem from '../../Components/RecordList/RecordListSortableHeaderItem.vue';
import AddDocumentModal from './AddDocumentModal.vue';


export default {
    components: {
        RecordList,
        RecordListItem,
        RecordListActionButton,
        RecordListSortableHeaderItem,
        AddDocumentModal
    },
    props: {
        records: {
            type: Array,
            required: true,
            default: () => []
        },
        documentable: {
            type: String,
            required: true,
            // default: 'property'
        }
    },
    data() {
        return {
            //
        }
    },
    computed: {
        // ...mapGetters({
        //     appliance: 'appliances/getAppliance',
        //     room: 'rooms/getRoom',
        //     property: 'properties/getCurrentProperty',
        //     service_record: 'service_records/getServiceRecord',
        // }),
    },
    methods: {
    
        async editRecord(record)
        {
            alert('Edit: ' + record.id);
            console.log('Edit', record);
        },
        viewRecord(record)
        {   
            // console.log('View', record);
            // return;
            var anchor = document.createElement('a');
            anchor.href = `/downloads?path=${record.filepath}`;
            anchor.target="_blank";
            anchor.click();
        },
        async generateEnergyReport(record)
        {
            await this.$store.dispatch('documents/generateEnergyReport', record)
                .then((response) => {
                    // console.log(response);
                    this.$notify({
                        type: 'success',
                        title: 'Generating Energy Report',
                        text: 'We are generating your energy report. This may take a few minutes.',
                        // duration: 3000,
                    });
                });
                // this.$modal.show('add-document-modal-property', {documentable: 'property', property: property});
            // alert('Generate Energy Report: ' + record.id);
            // console.log('Generate Energy Report', record);
        },
        addRecord()
        {
            this.$modal.show('add-document-modal', {documentable: this.documentable});
        },
        deleteRecord(record)
        {
            alert('Delete: ' + record.id);
            console.log('Delete', record);
        },
        duplicateRecord(record)
        {
            alert('Duplicate: ' + record.id);
            console.log('Duplicate', record);
        },
        /**
         * Get route depending on the documentable name.
         */
        // getRoute()
        // {
        //     // Appliance
        //     if(this.documentable == 'appliance') {
        //         return `/api/v1/appliances/${this.appliance.id}/documents`
        //     }
        //     // Service Record
        //     else if(this.documentable == 'service_record') {
        //         return `/api/v1/service_records/${this.service_record.id}/documents`
        //     }
        //     // Room
        //     else if(this.documentable == 'room') {
        //         return `/api/v1/rooms/${this.room.id}/documents`
        //     }
        //     // Property
        //     else if(this.documentable == 'property') {
        //         return `/api/v1/properties/${this.property.id}/documents`
        //     }

        // },
        // getActionForDocumentable()
        // {
        //     // Appliance
        //     if(this.documentable == 'appliance') {
        //         return 'appliances/addDocument'
        //     }
        //     // Service Record
        //     if(this.documentable == 'service_record') {
        //         return 'service_records/addDocument'
        //     }
        //     // Room
        //     if(this.documentable == 'room') {
        //         return 'rooms/addDocument'
        //     }
        //     // Property
        //     if(this.documentable == 'property') {
        //         return 'properties/addDocument'
        //     }
        // },
    }

}
</script>

<style>

</style>