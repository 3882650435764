let formDefaults =  {

    // Property Details
    id: {
        value: '',
        rules: 'required|integer',
    },

    // Address Details
    street: {
        value: '',
        rules: 'required|string',
        section: 'address'
    },
    unit_number: {
        value: '',
        rules: 'string',
        section: 'address'
    },
    city_id: {
        value: '',
        rules: 'required|integer',
        section: 'address'
    },
    state_id: {
        value: '',
        rules: 'required|integer',
        section: 'address'
    },
    country_id: {
        value: '',
        rules: 'required|integer',
        section: 'address'
    },
    postal_code: {
        value: '',
        rules: 'required|string',
        section: 'address'
    },


    // Basic Details
    property_type_id: {
        value: 1,
        rules: 'required|integer',
        section: 'basic_details'
    },
    nickname: {
        value: '',
        rules: 'string',
        section: 'basic_details'
    },
    roof_type: {
        value: '',
        rules: 'string',
        section: 'basic_details'
    },
    year_built: {
        value: '',
        rules: 'integer',
        section: 'basic_details'
    },
    area_sqft: {
        value: '',
        rules: 'integer',
        section: 'basic_details'
    },
    bedrooms: {
        value: '',
        rules: 'integer',
        section: 'basic_details'
    },
    bathrooms: {
        value: '',
        rules: 'integer',
        section: 'basic_details'
    },
    
}

let formConfig = {}

export { formDefaults, formConfig }
