<template>
    <Card>
        <div>
            <SectionTitle>Let's Meet!</SectionTitle>

            <p class="mb-4 text-sm">Book a 30 minute call with a team to review next steps and get to know you better.</p>

            <a :href="link" target="_blank" alt="Book a Call with Paddio">
                <RoundButton class="px-12 mb-4">Book a Call With Us</RoundButton>
            </a>
        </div>
    </Card>
</template>

<script>
export default {
    props: {
        link: {
            type: String,
            require: true,
        },
    }
}
</script>

<style>

</style>