<template>
    <!-- Property Selector Selector -->
    <div class="relative z-5 w-full md:w-1/2 lg:w-2/5 md:mr-4" v-click-outside="() => menuVisible = false" >

        <!-- Collapsed -->
        <button class="property-navigation flex bg-white pl-4 pr-2 py-2 items-center hover:bg-gray-100 w-full" @click="menuVisible = !menuVisible">
            <div class="flex-1 text-left overflow-x-hidden">
                <div class="whitespace-nowrap">
                    {{ property.address.street }}, {{ property.address.city.name }}, {{ property.address.state.name }}
                </div>
            </div>
            <div class="w-6 h-6 flex items-center justify-center ml-4 bg-white rounded-full">
                <v-icon name="caret-down"></v-icon>
            </div>
        </button>

        <!-- Expanded  -->
        <div v-if="menuVisible" class="absolute flex flex-col items-start rounded bg-white max-h-48 overflow-y-scroll w-full shadow-lg">
            <button v-for="(property, index) in selectableProperties" :key="index" class="w-full text-left pl-4 pr-2 py-2 hover:bg-gray-100" @click="setProperty(property)">
                {{ property.street }}, {{ property.city.name }}, {{ property.state.name }}
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ClickOutside from 'vue-click-outside';

export default {
    data() {
        return {
            menuVisible: false
        }
    },
    methods: {
        async setProperty(property)
        {
            await this.$store.dispatch('properties/getProperty', property.id);
            this.$store.dispatch('properties/getProjects', this.property.id)
            this.$store.dispatch('properties/getIncentives', this.property.id) 

            this.$router.push(`/properties/${this.property.id}`)

            this.menuVisible = false;
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/getUser',
            property: 'properties/getCurrentProperty',
            properties: 'users/getProperties'
        }),
        liveProperties() {
            return this.properties.filter(property => property.deleted_at == null);
        },
        selectableProperties()
        {
            return this.properties.filter(property => property.id != this.property.id);
        }
    },
    directives: {
        ClickOutside
    },
    created() {

    }

}
</script>

<style lang="scss" scoped>
    .property-navigation {
        //box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);    
    }
</style>