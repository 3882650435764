<template>

    <div class="w-full flex border border-1 rounded items-center cursor-pointer" 
        :class="[document.id  ? 'bg-white border-orange-500' : 'text-orange-700 border-gray-400 border-dashed hover:bg-orange-50 hover:border-orange-700 hover:border-solid']"
        @dragover="dragOver" 
        @dragleave="dragLeave" 
        @drop="drop"
    >
        <!-- Empty -->
        <div v-if="!document.id" class="w-full">

                <input type="file" name="file" id="file" ref="file" class="hidden" accept=".pdf" @change="onChange($event)" />

                <label for="file" class="file-label text-center cursor-pointer">
                    
                    <!-- Empty State -->
                    <div v-if="!isDragging && !form.submitting && !isDragOver" class="flex items-center justify-center p-4">
                        <v-icon name="plus-circle" class="fill-current mr-2" scale="1.25"></v-icon>
                        <span>Add</span>
                    </div>

                    <!-- Drag Over State -->
                    <div v-else-if="isDragOver" class="p-4">
                        Release to drop files here.
                    </div>

                    <!-- Submitting State -->
                    <div v-else-if="form.submitting" class="p-4">
                        <v-icon name="circle-notch" :spin="true" class="fill-current"></v-icon>
                    </div>
                </label>

        </div>

        <!-- filled --> 
        <div v-if="document.id" class="w-full flex items-center gap-x-4 p-2">

            <!-- Image/Icon -->
            <!-- <div class="w-12 h-12 p-2 rounded border border-1 border-black">
                <img :src="getIconLink()" class="w-full h-full object-cover rounded" alt=""/>
            </div> -->

            <!-- Name -->
            <!-- <a href="#" @click.prevent="viewDocument(document)" class="flex-1 text-gray-500 hover:underline">{{ document.name }}</a> -->

            <a href="#" @click.prevent="viewDocument(document)" class="w-full flex gap-x-3 items-center">
                <v-icon name="file-pdf" class="block fill-current w-4 h-4"></v-icon>
                <div class="text-sm">{{document.name}}</div>
                <!-- <DocumentInputCard class="flex-1" :document="homeownerInformationSheet" :documentType="homeownerInformationSheetType" :property-id="propertyId"></DocumentInputCard> -->
            </a>

        </div>
    </div>

</template>

<script>
import { mapGetters } from 'vuex';
import Form from '@lukejm/form-js'
import { formDefaults, formConfig } from '@property/Forms/CreateDocumentForm.js'

const DOCUMENT_TYPES = {
    'Homeowner Information Sheet': {
        name: 'Homeowner Information Sheet',
        icon: 'documents/table.svg'
    },
    'Renovation Upgrade Report': {
        name: 'Renovation Upgrade Report',
        icon: 'documents/plan1.svg'
    },
}

export default {
    props: {
        propertyId: {
            type: Number,
            // required: true
        },
        document: {
            type: Object,
            // required: true
        },
        documentType: {
            type: Object,
            // required: true
        },
    },
    computed: {
        ...mapGetters({
            document_types: 'documents/getDocumentTypes'
        }),
        active() {
            return !this.document.id;
        },
        
    },
    data() {
        return {
            form: new Form(formDefaults, formConfig),
            isDragging: false,
            isDragOver: false,
            files: [],
        }
    },
    methods: {
        getIconLink() {
            
            return this.image(DOCUMENT_TYPES[this.document.name]?.icon);
        },
        dragOver(e) {
            // console.log('dragOver', e);
            if(this.active) {
                e.preventDefault();
                this.isDragOver = true;
                // this.isDragging = true;
            }
           
        },
        dragLeave(e) {
            // console.log('dragLeave', e);
            if(this.active) {
                e.preventDefault();
                this.isDragOver = false;
            }
        },
        drop(e) {
            // console.log('drop', e);
            e.preventDefault();
            const event = {
                target: {
                    name: 'file',
                    files: e.dataTransfer.files
                }
            }

            // if(this.active) {
                this.isDragging = false;
                this.isDragOver = false;
                this.onChange(event)
            // }
        },
        onChange(event) {

            // console.log(event.target.files)
            // return;

            // console.log('onChange', event);
            this.form.name = this.documentType.name;
            this.form.addFile(event);

            this.submit();
        },


        submit()
        {
            const route = `/api/v1/properties/${this.propertyId}/documents`;

            // console.log('submit', route);

            this.form.post(route)
                // .then(response => {
                    // this.$store.dispatch(action, response.data.document)
                        .then((res) => {
                            // console.log('res', res);
                            // this.$modal.hide(this.name)
                            this.$notify({
                                title: 'Success',
                                text: 'The document has been added.',
                                type: 'success'
                            });
                            this.$store.dispatch('properties/getProperty', this.propertyId)
                        }).catch(error => {
                            
                            console.log(error);

                            this.$notify({
                                title: 'Error',
                                text: error.data.message,
                                type: 'error'
                            })
                        });
                // })
        },
        deleteDocument()
        {
            alert(`Deleting document: [${this.document.id}] ${this.document.name}`);
            // await this.$store.dispatch('documents/deleteDocument', this.document.id);
        },
        viewDocument(record)
        {   
            var anchor = document.createElement('a');
            anchor.href = `/downloads?path=${record.filepath}`;
            anchor.target="_blank";
            anchor.click();
        },
    }
}
</script>

<style>

</style>