let formDefaults =  {
    property_id: {
        value: null,
        rules: 'required|integer'
    },
    name: {
        value: 'Gym',
        rules: 'required|string'
    },
    floor_type:
    {
        value: '',
        rules: 'string'
    },
    other_name: {
        value: '',
        rules: 'required_if:name,Other|string'
    },
    nickname: {
        value: '',
        rules: 'string'
    },
    storey: {
        rules: 'integer'
    }
}

let formConfig = {}

export { formDefaults, formConfig }