<template>
    <Card class="relative">

        <ActionButtonStack v-if="isAdmin" class="absolute top-4 right-4">
            <template v-slot:actions>
                <!-- <ActionButton text="Generate Energy Report" @click="generateEnergyReport()"><v-icon name="chart-bar" class="fill-current"></v-icon></ActionButton> -->
                <ActionButton v-if="isAdmin" text="View Energy Profile" @click="$router.push({path: `/properties/${property.id}/energy-profile`})"><v-icon name="eye" class="fill-current"></v-icon></ActionButton>

            </template>
        </ActionButtonStack>

        <!-- <SectionTitle>Energy Profile</SectionTitle> -->
        <SectionTitle>Annual Energy<br> Consumption (GJ)</SectionTitle>


        <!-- Estimated Badge -->
        <!-- <div class="flex mb-4 md:mb-0 md:m-0 -mt-4 md:absolute top-4 right-6 ">
            <div class="rounded-full bg-gray-800 text-white py-1 px-4">Estimated</div>
        </div> -->

        <!-- Existing Profile -->
        <div class="mb-4">
            <!-- Rating vs typcial home (135) -->
            <EnergyProfileMeterNew :rating="rating" :potential-rating="potentialRating" class="w-full mb-6"></EnergyProfileMeterNew>

              <!-- Subpara -->
            <div class="text-sm">
                <!-- If an Energy Profile has been created for Property-->
                <p v-if="profile.energy_reports_count">The EnerGuide Pre-Upgrade Annual Energy Consumption score is expressed in GJ of energy. It is calculated by rigorous energy modelling by your Energy Advisor using the EnerGuide process.</p>
                <p v-else>The Estimated Energy Consumption Score is expressed in GJ of energy. It is calculated comparing your home's size, year built, and other attributes to industry averages. It is used for discussion purposes only.</p>
            </div>
        </div>

        <!-- Documents -->
        <div v-if="homeownerInformationSheet?.id && renovationUpgradeReport?.id" class="flex flex-col gap-4">
            <a href="#" @click.prevent="viewDocument(document)" v-for="(document, index) in documents" :key="index" class="w-full flex gap-x-3 items-center p-2 border border-orange-500 hover:bg-orange-50 rounded">
                <v-icon name="file-pdf" class="block fill-current w-4 h-4"></v-icon>
                <div>{{document.name}}</div>
            </a>
        </div>
    </Card>
</template>

<script>
import EnergyProfileMeter from './EnergyProfileMeter.vue';
import EnergyProfileMeterNew from './EnergyProfileMeterNew.vue';
import DonutChart from './DonutChart.vue';
import { mapGetters } from 'vuex';

export default {
    components: {
        EnergyProfileMeter,
        EnergyProfileMeterNew,
        DonutChart
    },
    props: {
        profile: {
            // type: Object,
            required: true
        },
        documents: {
            type: Array,
            // required: true
        },
    },
    data() {
        return {
            //
        }
    },
    computed: {
        ...mapGetters({
            isAdmin: 'auth/isAdmin',
            property: 'properties/getCurrentProperty',
        }),
        rating() {
            return this.profile?.current_energy_score || this.profile?.preliminary_energy_score || 0;
        },
        potentialRating() {
            return this.profile?.potential_energy_score || 0;
        },
        homeownerInformationSheet() {
            return this.documents?.find(document => document.name == 'Homeowner Information Sheet') ?? {};
            // return this.documents.find(document => document.name == 'Homeowner Information Sheet') ?? {};
        },
        renovationUpgradeReport() {
            return this.documents?.find(document => document.name == 'Renovation Upgrade Report') ?? {};
        },
    },
    methods: {
        viewDocument(record)
        {   
            var anchor = document.createElement('a');
            anchor.href = `/downloads?path=${record.filepath}`;
            anchor.target="_blank";
            anchor.click();
        },
    }
}
</script>

<style>

</style>