<template>
  <div class="border border-gray-100 rounded">
        <!-- Navigation Bar -->
        <div class="flex w-full items-center cursor-pointer" style="margin-left: -1px; margin-top: -1px;">
            <div 
                class="flex w-1/3 items-center justify-center h-12 border border-r border-b border-gray-100" 
                :class="[selectedIncentiveType=='grant' ? 'bg-orange-700 text-white' : 'bg-transparent text-gray-900 hover:bg-orange-100' ]" 
                @click="selectedIncentiveType = 'grant'"
            >Grants</div>

            <div 
                class="flex w-1/3 items-center justify-center h-12 border border-r border-b border-gray-100" 
                :class="[selectedIncentiveType=='rebate' ? 'bg-orange-700 text-white' : 'bg-transparent text-gray-900 hover:bg-orange-100' ]" 
                @click="selectedIncentiveType = 'rebate'"
            >Rebates</div>

            <div 
                class="flex w-1/3 items-center justify-center h-12 border-b border-gray-100" 
                :class="[selectedIncentiveType=='loan' ? 'bg-orange-700 text-white' : 'bg-transparent text-gray-900 hover:bg-orange-100' ]" 
                @click="selectedIncentiveType = 'loan'"
            >Loans</div>
        </div>
        <!-- List -->
        <div style="/*max-height: 12rem;*/" class="overflow-y-scroll">
            <!-- Rebates -->
            <div v-if="selectedIncentiveType == 'rebate'">
                <div v-if="incentives.rebates.length">
                    <div v-for="(rebate, index) in incentives.rebates" :key="index" class="flex w-full px-2 py-2">
                        <div class="flex-1">
                            <div>{{ rebate.service_type.name }}</div>
                            <div class="md:hidden text-sm">{{ rebate.retrofit_program.issuer_name }}</div>
                        </div>
                        <div class="hidden md:block w-1/4">{{ rebate.retrofit_program.issuer_name }}</div>
                        <div class="w-1/4 text-right">$ {{ (rebate.amount/100).toLocaleString() }}</div>
                    </div> 
                    <div class="flex w-full px-2 py-2 bg-gray-100">
                        <div class="flex-1 font-bold">Total</div>
                        <div class="w-1/4 text-right font-bold">$ {{ (incentives.rebates.reduce((accumulator, incentive) => accumulator + incentive.amount, 0)/100).toLocaleString() }}</div>
                    </div>
                </div>
                <div v-else>
                    <p class="px-2 py-4">Your property is not eligible for any rebates.</p>
                </div>
            </div>     
            
            <!-- Grants -->
            <div v-if="selectedIncentiveType == 'grant'">
                <div v-if="incentives.grants.length">
                    <div v-for="(grant, index) in incentives['grants']" :key="index" class="flex w-full px-2 py-2">
                        <div class="flex-1">
                            <div>{{ grant.service_type.name }}</div>
                            <div class="md:hidden text-sm">{{ grant.retrofit_program.issuer_name }}</div>
                        </div>
                        <div class="hidden md:block w-1/4">{{ grant.retrofit_program.issuer_name }}</div>
                        <div class="w-1/4 text-right">$ {{ (grant.amount/100).toLocaleString() }}</div>
                    </div> 
                    <div class="flex w-full px-2 py-2 bg-gray-100">
                        <div class="flex-1 font-bold">Total</div>
                        <div class="w-1/4 text-right font-bold">$ {{ (incentives.grants.reduce((accumulator, incentive) => accumulator + incentive.amount, 0)/100).toLocaleString() }}</div>
                    </div>
                </div>
                <div v-else>
                    <p class="px-2 py-4">Your property is not eligible for any grants.</p>
                </div>
            </div> 

            <!-- Loans -->
            <div v-if="selectedIncentiveType == 'loan'">
                <div v-if="incentives.loans.length">
                    <div v-for="(loan, index) in incentives['loans']" :key="index" class="flex w-full px-2 py-2">
                        <div class="flex-1">
                            <div>{{ loan.description }}</div>
                            <div class="md:hidden text-sm">{{ loan.retrofit_program.issuer_name }}</div>
                        </div>
                        <div class="hidden md:block w-1/4">{{ loan.retrofit_program.issuer_name }}</div>

                        <div class="w-1/4 text-right">$ {{ (loan.amount/100).toLocaleString() }}</div>
                    </div> 
                    <div class="flex w-full px-2 py-2 bg-gray-100">
                        <div class="flex-1 font-bold">Total</div>
                        <div class="w-1/4 text-right font-bold">$ {{ (incentives.loans.reduce((accumulator, incentive) => accumulator + incentive.amount, 0)/100).toLocaleString() }}</div>
                    </div>
                </div>
                <div v-else>
                    <p class="px-2 py-4">Your property is not eligible for any loans.</p>
                </div>
            </div> 
        </div>
    </div>
</template>

<script>
export default {
    name: 'IncentivesTable',
    // title: 'IncentivesTable',
    props: {
        incentives: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            selectedIncentiveType: 'rebate'
        }
    },
    methods: {
        toggleNavigation() {
            this.navigation_visible = !this.navigation_visible
        }
    }
}
</script>

<style>

</style>