<template>
    <Card>

        <div class="flex flex-col">
            <section-title>What to Expect</section-title>
            <div class="text-sm">
                <p class="mb-4">Based on your home's age and size we have create a preliminary energy model and found some ways to reduce your energy consumption.</p>
                <p>Every home is unique and ony with an <a href="https://www.paddio.ca/paddio-blog-posts/getting-your-energuide-evaluation" class="link" target="_blank">EnerGuide Home Evaluation</a> and qualified quotes will we know the specific upgrades for your home.</p>
            </div>

             <!-- Divider -->
            <div class="hidden border-b border-gray-200 my-6"></div>

            <!-- Energy Eval Learn More Button -->
            <div class="hidden">
                <h3 class="font-bold mb-6">Let Us Take It From Here</h3>
                <p class="mb-4">For a flat fee of $2.500, we'll manage the entire project from here, including all planning, managing quotes from trades, and a free EnerGuide Evaluation. <a href="#" class="link">Pay now</a>.</p>
                <!-- <a href="https://www.paddio.ca/paddio-blog-posts/getting-your-energuide-evaluation" target="_blank">
                    <SquareButton navigation class="w-full md:w-auto">
                        Get Your EnerGuide Evaluation
                    </SquareButton>
                </a> -->
                
            </div>
        </div> 

       

    </Card>
</template>

<script>
export default {

}
</script>

<style>

</style>