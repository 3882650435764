<template>
  <Card class="flex flex-col gap-4">
    <section-title class="mb-0">
        <v-icon name="spinner" class="fill-current animate-spin mr-2"></v-icon>
        <span>Generating Upgrades</span>
    </section-title>

    <!-- Divider -->
    <div class="border-b border-gray-200 -mt-4"></div>

    <!-- Text -->
    <div class="text-sm">
      <p>We are currently generating upgrade opportunities specific to this property.  We will contact you when this is completed.</p>
    </div>
  </Card>
</template>

<script>
export default {
  name: 'GeneratingEnergyReportInfoCard',
  // Your script code here
}
</script>
