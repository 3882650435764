<template>
    <div class="flex flex-col gap-6">

        <!-- Project details -->
        <div class="flex flex-col gap-4">
            <!-- Progress Graphic -->
            <div class="flex justify-between w-full relative">
                <StateCard 
                    v-for="(state, index) in getFormattedStates()" 
                    :key="index" 
                    :active="state.id == project?.state?.id" 
                    :state="state"
                    :first="index==0"
                    :completed="state.order < project?.state?.order"
                    class="flex flex-col items-center z-10"
                    @click="$router.push(`/projects/${project.id}`)"
                    :last="getFormattedStates().length == (index + 1)"
                >
                </StateCard>
            </div>

            
            <!-- Payment/Project Plan State -->
            <div v-if="hasPaidPlan" class="flex items-center p-2 gap-2 bg-orange-50 rounded text-sm text-orange-900">
                <v-icon name="clipboard-check" class="fill-current"></v-icon>
                <div>{{ project.plan.title }}</div>
            </div>

        </div>

        <!-- Plan Details -->
        <div class="rounded flex flex-col gap-y-4" v-if="project?.payments?.length">
                <!-- <SectionTitle>Project Plan</SectionTitle> -->

                <!-- Plan -->
                <div>
                    <table class="text-sm">
                        <tr>
                            <td class="pr-6 font-bold text-neutral-700">Plan:</td>
                            <td>{{ project.payments[0]?.plan?.title }}</td>
                        </tr>
                        <tr>
                            <td class="pr-6 font-bold text-neutral-700">Status:</td>
                            <td>
                                <span v-if="project.payments[0]?.status == 'PENDING'" class="text-blue-500 font-bold">Pending</span>
                                <span v-if="project.payments[0]?.status == 'PAID'" class="text-green-500 font-bold">Paid</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="pr-6 font-bold text-neutral-700">Price:</td>
                            <td>$ {{ project.payments[0]?.plan?.cost_dollars }}</td>
                        </tr>
                        <tr v-if="project.payments[0]?.status == 'PAID'">
                            <td class="pr-6 font-bold text-neutral-700">Paid At:</td>
                            <td>{{ project.payments[0]?.paid_at }}</td>
                        </tr>
                    </table>
                </div>  

                <!-- View plan button -->
                <router-link :to="`/projects/${project.id}/payment`" class="block">
                    <SquareButton context="gray">View Plan</SquareButton>
                </router-link>
            </div>


    </div>
    

</template>

<script>
import { mapGetters } from 'vuex';
import StateCard from '../StateCard.vue';

export default {
    components: {
        StateCard
    },
    props: {
        project: {
            // type: Object,
            required: true
        },
    },
    computed: {
        ...mapGetters({
            project_states: 'projects/getProjectStates',
        }),
        /**
         * Does project have a paid plan?
         */
        hasPaidPlan()
        {
            let hasProjectPlan = this.project?.plan?.id;

            let payments = this.project?.payments || [{}];
            let successfulPayments = payments.filter(payment => payment.status == 'SUCCEEDED');
            let hasSuccessfulPayment = successfulPayments.length > 0;

            return hasProjectPlan && hasSuccessfulPayment;
        }
    },
    methods: {
        getFormattedStates()
        {
            let formattedStates = this.project_states.filter(state => {
                // Omited states
                return !['CREATED', 'BOOK_CALL', 'ATTEND_CALL', 'CONFIRM_WORK', 'POST_ENERGY_EVALUATION' ,'CANCELLED', 'COMPLETED', 'FEEDBACK'].includes(state.short_title)
            });
            return formattedStates.slice(0, 4);

            // if(['xs', 'sm'].includes(this.$screen.breakpoint)) {
            //     let formattedStates = this.project_states.filter(state => {
            //         return state.order >= this.project?.state.order
            //     });
            //     return formattedStates.slice(0, 4);
            // } else if(['md'].includes(this.$screen.breakpoint)) {
            //     let formattedStates = this.project_states.filter(state => {
            //         return state.order >= this.project?.state.order
            //     });
            //     return formattedStates.slice(0, 4);

            // } else {
            //     let formattedStates = this.project_states.filter(state => {
            //         // Omited states
            //         return !['CREATED', 'BOOK_CALL', 'ATTEND_CALL', 'CONFIRM_WORK', 'POST_ENERGY_EVALUATION' ,'CANCELLED', 'COMPLETED', 'FEEDBACK'].includes(state.short_title)
            //     });
            //     return formattedStates.slice(0, 4);
            // }
        },
    }
}
</script>

<style>

</style>