import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import stats from './modules/stats'
import users from './modules/users'
import tasks from './modules/tasks'
import rooms from './modules/rooms'
import notes from './modules/notes'
import alerts from './modules/alerts'
import states from './modules/states'
import cities from './modules/cities'
import retrofit from './modules/retrofit'
import projects from './modules/projects'
import countries from './modules/countries'
import documents from './modules/documents'
import addresses from './modules/addresses'
import utilities from './modules/utilities'
import user_types from './modules/user_types'
import components from './modules/components'
import incentives from './modules/incentives'
import properties from './modules/properties'
import appliances from './modules/appliances'
import fuel_types from './modules/fuel_types'
import project_jobs from './modules/project_jobs'
import project_plans from './modules/project_plans'
import service_types from './modules/service_types'
import heating_types from './modules/heating_types'
import manufacturers from './modules/manufacturers'
import energy_reports from './modules/energy_reports'
import energy_profiles from './modules/energy_profiles'
import service_records from './modules/service_records'
// import component_types from './modules/component_types'
import service_companies from './modules/service_companies'
import retrofit_programs from './modules/retrofit_programs'
import household_details from './modules/household_details'
import project_opportunities from './modules/project_opportunities'
import retrofit_program_tiers from './modules/retrofit_program_tiers'
import air_conditioning_types from './modules/air_conditioning_types'
import retrofit_program_tier_conditions from './modules/retrofit_program_tier_conditions'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentProfile: 'owner',
    colors: {
      owner: {
        primary: '#745DFE',
        light: '#AC96FF',
        dark: '#4330B8'
      },
      tenant: {
        primary: '#DD4855',
        light: '#F07E8E',
        dark: '#A43446'
      },
      service: {
        primary: '#489493',
        light: '#7EC6C5',
        dark: '#357876'
      }
    }
  },
  mutations: {
    setCurrentProfile (state, profile) {
      state.currentProfile = profile
    }
  },
  actions: {
  },
  modules: {
    auth,
    stats,
    users,
    rooms,
    notes,
    tasks,
    alerts,
    states,
    cities,
    retrofit,
    
    projects,
    countries,
    documents,
    addresses,
    utilities,
    user_types,
    components,
    fuel_types,
    incentives,
    properties,
    
    appliances,
    project_jobs,
    heating_types,
    project_plans,
    manufacturers,
    service_types,
    energy_reports,
    
    // component_types,
    energy_profiles,
    service_records,
    service_companies,
    retrofit_programs,
    household_details,
    project_opportunities,
    air_conditioning_types,
    retrofit_program_tiers,
    retrofit_program_tier_conditions

  },
  getters: {
    currentUser (state) {
      return users.state.currentUser;
    },
    currentProfile (state) {
      return state.currentProfile
    },
    primaryColor (state) {
      return state.colors[state.currentProfile].primary
    },
    darkColor (state) {
      return state.colors[state.currentProfile].dark
    },
    lightColor (state) {
      return state.colors[state.currentProfile].light
    }
  }
})
