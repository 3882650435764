export default class User {


    /**
     * Find a user by it's ID.
     * @param {int} id 
     */
    static async find(id) {

        let request = await axios.get(`/api/v1/users/${id}`);
        return request.data?.user || {};
    }



    /**
    * Find all properties.
    *
    * @return {Array} users
    */
    static async findAll() {
        let request = await axios.get(`/api/v1/users`);
        return request.data?.users || {};
    }

    /**
     * Get properties for a user by ID.
     * @param {int} id 
     * @returns {Array}
     */
    static async getProperties(id)
    {
        if(id) {
            let response = await axios.get(`/api/v1/users/${id}/properties`);
            return response.data?.properties || {};
        }
    }
}