<template>
  <div class="flex flex-1 flex-col items-center">    
        <div class="relative w-full flex justify-center mb-2">

            <!-- Icon -->
            <a href="#" class="z-10" @click.prevent="$emit('click')">
                <!-- Completed -->
                <div v-if="completed" 
                    class="rounded-full h-12 w-12 flex items-center justify-center border border-transparent border-gray-300 text-gray-500 bg-gray-300">
                    <v-icon name="check" class="fill-current"></v-icon>
                </div>

                <!-- Not Completed -->
                <div v-else class="rounded-full h-12 w-12 flex items-center justify-center border border-transparent" :class="[active ? 'bg-orange-700 hover:bg-orange-600 text-white' : 'border-gray-500 text-gray-500 bg-gray-50 hover:bg-gray-100']">
                    <v-icon :name="getIconForState || 'question'" class="fill-current"></v-icon>
                </div>
            </a>
           

             <!-- Progress Line -->
            <div class="absolute top-1/2 w-full z-0" :class="[completed || active ? 'pl-0' : 'pl-0.5']">
                <div class="relative">
                     <!-- Left -->
                    <div v-if="!first" class="absolute w-1/2 left-0 h-0.5 border border-gray-300" :class="[completed || active ? ' border-solid' : 'border-dashed']"></div>
                    <!-- Right -->
                    <div v-if="!last" class="absolute w-1/2 right-0 h-0.5 border border-gray-300" :class="[completed ? ' border-solid' : 'border-dashed']"></div>
                </div>

                <!-- {{state.short_title}} -->
               
            </div>
        </div>

        <!-- text -->
        <div class="text-xs w-2/3 text-center" :class="[active ? 'font-bold' : '']">
            {{ state.title }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        active: {
            type: Boolean,
            required: true
        },
        state: {
            type: Object,
            required: true
        },
        first: {
            type: Boolean,
            required: false,
            default: false
        },
        last: {
            type: Boolean,
            required: false,
            default: false
        },
        completed: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        getProgressLineClasses() {
            // Ccompleted = all black
            // in progress means left black, right gray,
            // no started means all gray
        },
        getIconForState() {
            // console.log(state.short_title);
            switch (this.state.short_title) {
                case 'BOOK_CALL':
                    return 'calendar';
                case 'ATTEND_CALL':
                    return 'phone';
                case 'PRELIMINARY_ENERGY_EVALUATION':
                    return 'search';
                case 'ASSIGN_WORK':
                    return 'clipboard-list';
                case 'CONFIRM_WORK':
                    return 'users';
                case 'CONDUCT_WORK':
                    return 'wrench';
                case 'POST_ENERGY_EVALUATION':
                    return 'search';
                case 'FINALIZE_INCENTIVES':
                    return 'money-bill-wave';
            }
        }
    }
}
</script>

<style>

</style>