let formDefaults =  {
    project_id: {
        value: null,
        rules: 'required|integer',
    },
    project_opportunity_id: {
        value: null,
        rules: 'nullable|integer',
    },
    name: {
        value: '',
        rules: 'required|string',
    },
    description: {
        value: '',
        rules: 'required|string',
    },
    service_type_id: {
        value: null,
        rules: 'required|integer',
    },
}

let formConfig = {}

export { formDefaults, formConfig }
