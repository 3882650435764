<template>
    <Card class="border border-transparent" :class="{'border-orange-700': selected}">

        <!-- Admin action -->
        <div v-if="isAdmin" class="z-10 absolute right-6 top-6 md:right-4 md:top-4">
            <ActionButtonStack>
                <template v-slot:actions>
                    <ActionButton text="Create Job" @click="createJob()"><v-icon name="hammer" class="fill-current"></v-icon></ActionButton>
                </template> 
            </ActionButtonStack>
        </div>

        <div class="relative flex">
            <!-- Content -->
            <div class="flex flex-col gap-y-2 md:flex-row">


                <!-- Left -->
                <div class="relative mr-8 w-full h-32 md:h-60 md:w-60">

                    <!-- Selector -->
                    <button class="hidden group absolute top-2 left-2" @click="selected = !selected">
                        <!-- Selected -->
                        <div v-if="selected" class="flex items-center justify-center rounded-full h-8 w-8 border border-orange-700 bg-orange-700 group-hover:bg-orange-600">
                            <v-icon name="check" class="fill-current text-white"></v-icon>
                        </div>

                        <!-- Unselected -->
                        <div v-if="!selected" class="flex items-center justify-center rounded-full h-8 w-8 bg-white border border-gray-700 group-hover:bg-gray-100">
                            <v-icon name="check" class="text-white group-hover:text-gray-700 fill-current"></v-icon>
                        </div>
                    </button>


                    <!-- Image -->
                    <div class="w-full h-full bg-gray-100 rounded overflow-hidden">
                        <img v-if="opportunity.image_url" :src="image(opportunity.image_url)" :alt="opportunity.service_type?.name" class="w-full md:w-auto h-full object-cover">
                    </div>
                </div>

                <!-- Right -->
                <div class="flex flex-1 flex-col md:w-2/5 bg-white">
                    <!-- Desription -->
                    <h2 class="text-lg mb-4">{{ opportunity.service_type?.name}}</h2>
                    <div class="mb-4 md:mb-8 text-sm">{{ opportunity.description }}</div>
                    <a :href="opportunity.info_url" class="link flex items-center gap-x-4" target="_blank">
                        <span class="block">Learn More</span>
                        <v-icon name="chevron-right" class="fill-current" scale="0.875"></v-icon>
                    </a>

                    <!-- Divider -->
                    <div class="border-b border-gray-200 my-6"></div>

                    <!-- Lower -->
                    <div>

                        <ul class="incentive-list text-sm rounded">
                            <li v-for="(rebate, index) in opportunity.rebates" :key="`R${index}`" class="flex justify-between px-2 py-2">
                                <Link :href="rebate.source_url || rebate.program.url" class="incentive-program-link">{{ rebate.program.name }} Rebate</Link>
                                <div class="ml-2 font-semibold">${{ (rebate.amount/100).toLocaleString() }}</div>
                            </li>
                            <li v-for="(grant, index) in opportunity.grants" :key="`G${index}`" class="flex justify-between px-2 py-2">
                                <Link :href="grant.source_url || grant.program.url" class="incentive-program-link">{{ grant.program.name }} Grant</Link>
                                <div class="ml-2 font-semibold">${{ (grant.amount/100).toLocaleString() }}</div>
                            </li>
                            <li v-for="(loan, index) in opportunity.loans" :key="`L${index}`" class="flex justify-between px-2 py-2">
                                <Link :href="loan.source_url || loan.program.url" class="incentive-program-link">{{ loan.program.name }} Loan</Link>
                                <div class="ml-2 font-semibold">${{ (loan.amount/100).toLocaleString() }}</div>
                            </li>
                        </ul>

                    <!-- <div class="flex flex-col md:flex-row gap-y-2 gap-x-8"> -->

                        <!-- Grants & Rebates -->
                        <!-- Rebate -->
                        <!-- <div v-if="opportunity.rebates?.length">
                            <p class="text-xs lg:text-sm">{{getLargestRebate?.program?.name}} Rebate</p>
                            <p class="text-orange-700">${{ (getLargestRebate?.amount/100).toLocaleString() }}</p>
                        </div> -->

                        <!-- Grant -->
                        <!-- <div v-if="opportunity.grants?.length">
                            <p class="text-xs lg:text-sm">{{getLargestGrant?.program?.name}} Grant</p>
                            <p class="text-orange-700">${{ (getLargestGrant?.amount/100).toLocaleString() }}</p>
                        </div> -->

                        <!-- Loan -->
                        <!-- <div v-if="opportunity.loans?.length">
                            <p class="text-xs lg:text-sm">{{getLargestLoan?.program?.name}} Loan</p>
                            <p class="text-orange-700">${{ (getLargestLoan?.amount/100).toLocaleString() }}</p>
                        </div> -->
                    <!-- </div> -->
                    </div>
                </div>
            </div>

        </div>

        <!-- Modals -->
        <CreateProjectJobModal></CreateProjectJobModal>
    </Card>
</template>

<script>
import { mapGetters } from 'vuex';
import CreateProjectJobModal from '../Job/CreateProjectJobModal.vue';

export default {
    components: {
        CreateProjectJobModal,
    },
    data() {
        return {
            selected: false,
        }
    },
    props: {
        opportunity: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapGetters({
            isAdmin: 'auth/isAdmin',
        }),
        getLargestRebate()
        {
            let opp = this.opportunity.rebates.sort(this.compareByIncentiveAmount).reverse()[0];
            return opp;
        },
        getLargestGrant()
        {
            let opp = this.opportunity.grants.sort(this.compareByIncentiveAmount).reverse()[0];
            return opp;
        },
        getLargestLoan()
        {
            let opp = this.opportunity.loans.sort(this.compareByIncentiveAmount).reverse()[0];
            return opp;
        },
    },
    methods: {
            /**
             * Get rebate amount for opportunity.
             */
             getRebatesAmountForOpportunity()
            {
                var amount = this.opportunity.rebates.reduce((accumulator,rebate) => accumulator + rebate.amount, 0);
                this.totalGrantsAndRebates += amount;

                return amount
            },
            /**
             * Get grant amount for opportunity.
             */
            getGrantsAmountForOpportunity()
            {
                var amount = this.opportunity?.grants.reduce((accumulator,grant) => accumulator + grant.amount, 0);
                this.totalGrantsAndRebates += amount;

                return amount
            },
            /**
             * Get loan amount for opportunity.
             */
             getLoansAmountForOpportunity()
            {
                var amount = this.opportunity?.loans.reduce((accumulator,loan) => accumulator + loan.amount, 0);
                this.totalLoansAndRebates += amount;

                return amount
            },
            /**
             * Compare by incentive amount.
             * @param {Object} a
             * @param {Object} b
             */
           compareByIncentiveAmount(a, b) {
                return a.amount - b.amount;
            },
            /**
             * Create job.
             */
            createJob()
            {
               this.$modal.show('create-project-job-modal', {opportunity: this.opportunity});
            },
        

    }
}
</script>

<style lang="scss" scoped>

ul.incentive-list > li:nth-child(odd) {
    background-color: #f9f9f9;
}

.incentive-program-link {
    @apply text-black;
}
</style>