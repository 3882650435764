<template>
  <Card>
    <div class="flex items-center justify-between">
        <SectionTitle>Property Details</SectionTitle>   
        <div class="flex -mt-4">
            <SquareButton context="gray" class="mr-2" @click="refreshForm()">Reset</SquareButton>
            <SquareButton @click="submit()" :loading="form.submitting">Save</SquareButton>
        </div>
    </div>

    <FormComponent class="mb-8" :key="rerenderKey">
        <Field label="ID" disabled name="id" v-model="form.id" placeholder=""></Field>

        <Field label="Street" name="street" v-model="form.street" placeholder=""></Field>
        <Field label="Unit Number" name="unit_number" v-model="form.unit_number" placeholder=""></Field>  

        <!-- Country -->
        <Field type="select" label="Country" name="country_id" v-model="form.country_id" :text="getCountryText" placeholder="Select a country" :link="`/admin/countries/${form.country_id}`">
            <option v-for="(option, index) in getCountries" :key="index" :value="option.value" :selected="form.country_id == option.value">{{ option.name }}</option>
        </Field>

        <!-- State -->
        <Field type="select" label="Province" name="state_id" v-model="form.state_id" :text="getStateText" placeholder="Select a province" :link="`/admin/states/${form.state_id}`">
            <option v-for="(option, index) in getStates" :key="index" :value="option.value" :selected="form.state_id == option.value">{{ option.name }}</option>
        </Field>

        <!-- City -->
        <Field type="select" label="City" name="city_id" v-model="form.city_id" :text="getCityText" placeholder="Select a city" :link="`/admin/cities/${form.city_id}`">
            <option v-for="(option, index) in getCities" :key="index" :value="option.value" :selected="form.city_id == option.value">{{ option.name }}</option>
        </Field>

        <!-- Postal Code -->
        <Field label="Postal Code" name="postal_code" v-model="form.postal_code" placeholder=""></Field>

        <!-- Property Type -->
        <Field type="select" label="Property Type" name="property_type_id" v-model="form.property_type_id" :text="getPropertyTypeText" :options="[]" placeholder="Select a property type">
            <option v-for="(option, index) in getPropertyTypes" :key="index" :value="option.value" :selected="form.property_type_id == option.value">{{ option.name }}</option>
        </Field>

        <!-- Area Sqft -->
        <Field label="Area Sqft" name="area_sqft" v-model="form.area_sqft" placeholder=""></Field>


    </FormComponent>

    <!-- State ID:  {{ form.state_id }} -->

  </Card>
</template>

<script>
import Form from '@lukejm/form-js'
import Field from '../../Admin/Components/Form/Field.vue';
import FormComponent from '../../Admin/Components/Form/Form.vue';
import {formDefaults} from '@property/Forms/UpdatePropertyForm.js'
import { mapGetters } from 'vuex';

export default {
    name: "UpdatePropertyCard",
    components: {
        FormComponent,
        Field,
    },
    props: {
        property: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters({
            cities: 'addresses/getCities',
            states: 'addresses/getStates',
            countries: 'addresses/getCountries',
            propertyTypes: 'properties/getPropertyTypes',
        }),
        getCities()
        {
            return this.cities.map(city => {
                return {
                    value: city.id, name: city.name
                }
            })
        },
        getStates()
        {
            return this.states.map(state => {
                return {
                    value: state.id, name: state.name
                }
            })
        },
        getCountries()
        {
            return this.countries.map(country => {
                return {
                    value: country.id, name: country.name
                }
            })
        },
        getPropertyTypes()
        {
            return this.propertyTypes.map(propertyType => {
                return {
                    value: propertyType.id, name: propertyType.name
                }
            })
        },
        getCountryText()
        {
            return this.countries.find(country => country.id == this.form.country_id)?.name
        },
        getStateText()
        {
            return this.states.find(state => state.id == this.form.state_id)?.name
        },
        getCityText()
        {
            return this.cities.find(city => city.id == this.form.city_id)?.name
        },
        getPropertyTypeText()
        {
            return this.propertyTypes.find(propertyType => propertyType.id == this.form.property_type_id)?.name
        },
    },
    data() {
        return {
            form: new Form(formDefaults),
            dirty: false,
            rerenderKey: 0,
        };
    },
    async created() {
        await this.$store.dispatch('properties/getPropertyTypes');
        await this.$store.dispatch('properties/getRoofTypes');
        await this.$store.dispatch('addresses/setCountries');    
    },
    watch: {
        property: {
            handler() {
                this.fillForm();
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        async submit() {
            this.form.submitting = true;
            await this.$store.dispatch('properties/updateProperty', this.form.data())
                .then(() => {
                    this.form.submitting = false;
                    this.refreshForm();
                    this.$notify({
                        title: 'Success',
                        text: `Your property has been updated.`,
                        type: 'success'
                    });

                    this.$store.dispatch('properties/getProperty', this.property.id);

                    // this.$router.go(); // Reload page
                }).catch(error => {
                    console.error(error);

                    this.$notify({
                        title: 'Error',
                        text: error.message,
                        type: 'error'
                    })
                });
        },
        refreshForm() {
            // re-render component key to trigger a refresh - set fields back to view mode.
            this.rerenderKey++;
        },
        fillForm() {
            this.fillFormVerbose();
            return;

            let fieldNames = Object.keys(this.form.originalData);
            let values = {}

            fieldNames.forEach((fieldName) => {
                this.form[fieldName] = 
                    this.property[fieldName] || 
                    this.property?.address[fieldName];
            });
        },
        async fillFormVerbose()
        {
            // Fill property fields
            this.form.id = this.property.id
            this.form.street = this.property.address.street
            this.form.unit_number = this.property.address.unit_number
            this.form.country_id = this.property.address.country.id
            this.form.state_id = this.property.address.state.id
            this.form.city_id = this.property.address.city.id
            this.form.postal_code = this.property.address.postal_code
            this.form.roof_type = this.property.roof_type
            this.form.year_built = this.property.year_built
            this.form.property_type_id = this.property.property_type.id
            this.form.bedrooms = this.property.bedroom_count
            this.form.bathrooms = this.property.bathroom_count
            this.form.area_sqft = this.property.area_sqft

            // Fill State
            if(this.form.country_id) {
                await this.$store.dispatch('addresses/setStatesForCountry', this.form.country_id);
                this.form.state_id = this.property.address.state.id
            }

            // Fill City
            if(this.form.state_id) {
                await this.$store.dispatch('addresses/setCitiesForState', this.form.state_id);
                this.form.city_id = this.property.address.city.id
            }
           
        }
    }
};
</script>