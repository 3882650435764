import Vue from 'vue'

// Buttons
import SquareButton from '@components/Buttons/SquareButton'
import RoundButton from '@components/Buttons/RoundButton'
import DocumentButton from '@document/DocumentButton'
import AddButton from '@components/Buttons/AddButton.vue'
import ActionButton from '@components/Buttons/ActionButton.vue'
import ActionButtonStack from '@components/Buttons/ActionButtonStack.vue'
import Card from '@components/Card/Card'

Vue.component('SquareButton', SquareButton)
Vue.component('RoundButton', RoundButton)
Vue.component('DocumentButton', DocumentButton)
Vue.component('AddButton', AddButton)
Vue.component('ActionButton', ActionButton)
Vue.component('ActionButtonStack', ActionButtonStack)
Vue.component('Card', Card)

// Input Components
import Label from '@components/Inputs/Label'
import TextInput from '@components/Inputs/Text'
import FileInput from '@components/Inputs/File'
import SelectInput from '@components/Inputs/Select'
import MultiselectInput from '@components/Inputs/Multiselect'
import TextareaInput from '@components/Inputs/Textarea'
import Checkbox from '@components/Inputs/Checkbox'

Vue.component('Label', Label)
Vue.component('SelectInput', SelectInput)
Vue.component('MultiselectInput', MultiselectInput)
Vue.component('TextInput', TextInput)
Vue.component('FileInput', FileInput)
Vue.component('TextareaInput', TextareaInput)
Vue.component('CheckboxInput', Checkbox)

// Form Components
import FormRow from '@components/Form/FormRow'
import Errors from '@components/Form/Errors'
import FormGroup from '../Admin/Components/Form/FormGroup'

Vue.component('FormRow', FormRow)
Vue.component('Errors', Errors)
Vue.component('FormGroup', FormGroup)


// Other
import Pill from'@components/Pill.vue'
import SectionTitle from '@components/SectionTitle'
import Breadcrumb from '@components/Breadcrumb'
import Link from '@components/Link'
import PageTitle from '@components/PageTitle'

Vue.component('Pill', Pill)
Vue.component('SectionTitle', SectionTitle)
Vue.component('Link', Link)
Vue.component('Breadcrumb', Breadcrumb)
Vue.component('PageTitle', PageTitle)

// Vue.component('Modal', Modal)


// Layout Components
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'

Vue.component('Header', Header)
Vue.component('Footer', Footer)

