<template>
    <Modal :name="name" width="600" height="auto" @before-open="beforeOpen">
        <div class="p-6 pt-16 md:p-16">
           <!-- Title -->
           <SectionTitle>Add Utility</SectionTitle>

            <!-- Form -->
            <div id="create-utility-form" class="mb-8">

                <!-- Utility Type -->
                <FormRow>
                    <Label for="utility_type_id">Utility Type</Label>
                    <SelectInput name="utility_type_id" v-model="form.utility_type_id" class="2/3" @change="updateUtilityList">
                        <option v-for="typee in utility_types" :key="typee.id" :value="typee.id">{{typee.name}}</option>
                    </SelectInput>
                    <Errors v-if="form.errors.has('utility_type_id')">{{form.errors.first('utility_type_id')}}</Errors>
                </FormRow>

                <!-- Utility Name -->
                <FormRow>
                    <Label for="utility_id">Utility Name</Label>
                    <SelectInput name="utility_id" v-model="form.utility_id" class="2/3">
                        <option v-for="util in selectableUtilities" :key="util.id" :value="util.id">{{util.name}}</option>
                    </SelectInput>
                    <Errors v-if="form.errors.has('utility_id')">{{form.errors.first('utility_id')}}</Errors>
                </FormRow>

                <!-- Utility Other Name -->
                <!-- <FormRow>
                    <Label for="appliance_type_id">Utility Name</Label>
                    <SelectInput name="appliance_type_id" v-model="form.utility_id" class="2/3">
                        <option v-for="util in selectableUtilities" :key="util.id" :value="util.id">{{util.name}}</option>
                    </SelectInput>
                    <Errors v-if="form.errors.has('utility_id')">{{form.errors.first('utility_id')}}</Errors>
                </FormRow> -->

                <FormRow>
                    <Label for="service_provider">Utility Service Provider (Optional)</Label>
                    <TextInput name="service_provider" v-model="form.service_provider" class="2/3" placeholder="ie. Hydro One, Hydro Québec"></TextInput>
                    <Errors v-if="form.errors.has('service_provider')">{{form.errors.first('service_provider')}}</Errors>
                </FormRow>
            </div>

            <!-- Actions -->
            <FormRow>
                <square-button context="primary" class="mr-2" @click="submit">Submit</square-button>
                <square-button context="gray" @click="$modal.hide(name)">Close</square-button>
            </FormRow>
            
        </div>
    </Modal>
</template>

<script>
import Modal from '@components/Modal'
import SectionTitle from '@components/SectionTitle'
import Form from '@lukejm/form-js'
import {formDefaults, formConfig} from '@property/Forms/CreateUtilityForm.js'
import SquareButton from '@components/Buttons/SquareButton.vue'
import { mapGetters } from 'vuex'

export default {
    components: {
        Modal,
        SectionTitle,
        SquareButton,
    },
    data()
    {
        return {
            name: 'create-utility-modal',
            form: new Form(formDefaults),
            selectableUtilities: []
        }
    },
    computed: {
        ...mapGetters({
            utility_types: 'utilities/getUtilityTypes',
            utilities: 'utilities/getUtilities'
        })   
    },
    methods:
    {
        beforeOpen(event)
        {},
        updateUtilityList(utilityTypeId)
        {
            this.selectableUtilities = this.utilities.filter(item => item.type.id == utilityTypeId);
        },
        async submit()
        {
            // console.log(this.form.data());
            await this.$store.dispatch('properties/addUtility', this.form.data())
                .then(() => {
                    this.$modal.hide(this.name)

                    this.$notify({
                        title: 'Success',
                        text: 'Your utility has been added.',
                        type: 'success'
                    });
                }).catch(error => {

                    console.log(error);
                    this.$notify({
                        title: 'Error',
                        text: error.message,
                        type: 'error'
                    })
                });
        }
    },
    async created() {
        await this.$store.dispatch('utilities/setUtilityTypes');
        await this.$store.dispatch('utilities/setUtilities');
    }
}
</script>
