<template>
        <RecordList>
            <!-- Title -->
            <RecordListItem title>

                <div class="flex">
                    <h2 class="text-lg mb-0 flex-1">Rooms</h2>
                </div>

                <!-- Controls -->
                <template v-slot:controls>
                    <!-- <TextInput placeholder="Search" class="w-64" v-model="filterText">
                        <template v-slot:icon><v-icon name="search" class="fill-current"></v-icon></template>
                    </TextInput> -->
                </template>

                <!-- Important Actions -->
                <template v-slot:important-actions>
                    <RecordListActionButton important text="Add" @click="addRecord"><v-icon name="plus" class="fill-current"></v-icon></RecordListActionButton>
                </template>

                <template v-slot:actions>
                    <!-- <RecordListActionButton text="Add" @click="addRecord"><v-icon name="plus" class="fill-current"></v-icon></RecordListActionButton> -->
                </template>
            </RecordListItem>

            <!-- Headers -->
            <RecordListItem header>
                <div class="flex font-bold">
                    <div class="flex-1">Type</div>
                    <div class="w-1/2">Nickname</div>
                    <!-- Additional Column Names -->
                </div>
            </RecordListItem>

            <!-- Items -->
            <RecordListItem v-for="(record, index) in filteredRecords" :key="index">
                <!-- Details  -->
                <div class="flex">
                    <div class="flex-1">{{ record.name }}</div>
                    <div class="w-1/2">{{ record.nickname }}</div>
                    <!-- Additonal Column Values -->
                </div>

                <!-- Important Actions -->
                <template v-slot:important-actions>
                    <!-- <RecordListActionButton important text="Retrofit Report" @click="viewRetrofitReport(record)"><v-icon name="clipboard-list" class="fill-current"></v-icon></RecordListActionButton> -->
                    <RecordListActionButton important text="Edit" @click="editRecord(record)"><v-icon name="pen" class="fill-current"></v-icon></RecordListActionButton>
                </template>

                <!-- Other Actions -->
                <template v-slot:actions>
                    <!-- <RecordListActionButton text="Delete" @click="deleteRecord(record)"><v-icon name="trash" class="fill-current"></v-icon></RecordListActionButton> -->
                    <!-- <RecordListActionButton text="Duplicate" @click="duplicateRecord(record)"><v-icon name="copy" class="fill-current"></v-icon></RecordListActionButton> -->
                </template>
            </RecordListItem>

            <!-- Modals -->
            <CreateRoomModal/>
            <UpdateRoomModal/>

        </RecordList>
</template>

<script>
import RecordList from '../../../Components/RecordList/RecordList.vue';
import RecordListItem from '../../../Components/RecordList/RecordListItem.vue';
import RecordListActionButton from '../../../Components/RecordList/RecordListActionButton.vue';
import CreateRoomModal from './CreateRoomModal.vue';
import UpdateRoomModal from './UpdateRoomModal.vue';

export default {
    components: {
        RecordList,
        RecordListItem,
        RecordListActionButton,
        CreateRoomModal,
        UpdateRoomModal
    },
    props: {
        records: {
            type: Array,
            required: true,
            default: () => []
        },
        SearchBy: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            filterText: ''
        }
    },
    computed: {
        filteredRecords() {
            if (this.filterText.length > 0) {
                return this.records.filter(record => {
                    return this.SearchBy.some(filter => {
                        if(typeof record[filter] == 'string') 
                        {
                            return record[filter].toLowerCase().includes(this.filterText.toLowerCase());
                        }
                        return toString(record[filter]).toLowerCase().includes(this.filterText.toLowerCase());
                    })
                })
            }

            return this.records;
        }
    },
    methods: {
        async editRecord(record)
        {
            await this.$store.dispatch('rooms/getRoom', record.id);
            this.$modal.show('update-room-modal');
        },
        addRecord()
        {
            this.$modal.show('create-room-modal');
        },
        deleteRecord(record)
        {
            alert('Delete: ' + record.id);
            console.log('Delete', record);
        },
        duplicateRecord(record)
        {
            alert('Duplicate: ' + record.id);
            console.log('Duplicate', record);
        },
    }
    
}
</script>

<style>

</style>