<template>
  <div>
    <div class="flex items-center">
        <!-- Icon -->
        <div class="w-12 flex justify-center mr-6">
            <v-icon :name="iconName" :scale="2.25"></v-icon>
        </div>

        <!-- Text -->
        <div class="flex-1">
            <p><slot></slot></p>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        iconName: {
            type: String,
            require: true
        }
    }
}
</script>

<style>

</style>