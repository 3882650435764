/**
 * MS Clarity Service Adapter
 * @see https://clarity.microsoft.com/projects
 */

// The MS Clarity Project ID (found in Settings/Overview, in the URL, or in code snippets based on MS Clarity project)
const clarityProjectID = process.env.MIX_CLARITY_PROJECT_ID;

// The ID of the Clarity script HTML element
const scriptId = "clarity-script";

/**
 * Load Clarity script into DOM.
 */
function loadClarityScript() {
    if (document.getElementById(scriptId)) {
        return; // Script is already added
    }

    (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        t.id = scriptId; // Add an id to the script for removal later
    })(window, document, "clarity", "script", clarityProjectID);
}


/**
 * Remove Clarity script from DOM.
 */
function removeClarityScript() {

    // Override clarity and related methods to stop tracking
    window.clarity = function() {};

    // Clear any existing timeouts and intervals
    let highestTimeoutId = setTimeout(() => {});
    for (let i = 0; i <= highestTimeoutId; i++) {
        clearTimeout(i);
    }

    let highestIntervalId = setInterval(() => {});
    for (let i = 0; i <= highestIntervalId; i++) {
        clearInterval(i);
    }

    // Remove the Clarity script element
    const script = document.getElementById(scriptId);
    if (script) {
        script.parentNode.removeChild(script);
    }

    // Also remove any dynamically loaded Clarity scripts
    const clarityScripts = document.querySelectorAll('script[src^="https://www.clarity.ms/s/"]');
    clarityScripts.forEach((clarityScript) => {
        clarityScript.parentNode.removeChild(clarityScript);
    });
}

export { 
    loadClarityScript, 
    removeClarityScript
};