<template>
    <!-- EnergyGuide Report CTA -->
    <Card v-if="documents.length < 2">
        <section-title>Upload your EnerGuide Evaluation Reports</section-title>

        <!-- Progress Bar -->
        <div class="w-full bg-gray-200 rounded-full mb-4 overflow-hidden flex">
            <div id="progressBar" class="h-4 bg-orange-600 text-xs font-medium text-orange-100 text-center p-0.5 leading-none rounded-l-full" :style="{width: `${progressPercent}%`}">
                <span v-show="progressPercent > 0">{{ progressPercent }}%</span>
            </div>
            <div class="flex-1 text-xs text-center">
                <span v-show="progressPercent == 0">0%</span>
            </div>
        </div>

        <p class="mb-4 text-sm">Upload your reports to a get a free breakdown of your project that can be shared with your family or with trades.</p>

        <!-- Documents -->
        <ol class="w-full flex flex-col gap-x-4 gap-y-6">

            <!-- Homeowner Info Sheet -->
            <li class="w-full flex flex-col" :class="[homeownerInformationSheet ? '' : 'p-4 bg-gray-50']">
                <h3 v-if="!homeownerInformationSheet.id" class="font-bold mb-4">1.  Homeowner Information Sheet</h3>
                <DocumentInputCard class="flex-1" :document="homeownerInformationSheet" :documentType="homeownerInformationSheetType" :property-id="propertyId"></DocumentInputCard>
            </li>

            <!-- Renovation Upgrade Report -->
            <li class="w-full flex flex-col" :class="[renovationUpgradeReport ? '' : 'p-4 bg-gray-50']">
                <h3 v-if="!renovationUpgradeReport.id" class="font-bold mb-4">2.  Renovation Upgrade Report</h3>
                <DocumentInputCard class="flex-1" :document="renovationUpgradeReport" :documentType="renovationUpgradeReportType" :property-id="propertyId"></DocumentInputCard>
            </li>

        </ol>

        
    </Card>
</template>

<script>
import { mapGetters } from 'vuex';
import DocumentInputCard from './DocumentInputCard.vue';

export default {
    components: {
        DocumentInputCard
    },
    props: {
        propertyId: {
            // type: Number,
            // required: true
        },
        documents: {
            type: Array,
            required: true
        }
    },
    methods: {
        //
    },
    computed: {
        ...mapGetters({
            document_types: 'documents/getDocumentTypes',
            property: 'properties/getCurrentProperty',
            isAdmin: 'auth/isAdmin'
        }),
        homeownerInformationSheet() {
            return this.documents.find(document => document.name == 'Homeowner Information Sheet') ?? {};
        },
        homeownerInformationSheetType() {
            return this.document_types.find(type => type.name == 'Homeowner Information Sheet') ?? {};
        },
        renovationUpgradeReport() {
            return this.documents.find(document => document.name == 'Renovation Upgrade Report') ?? {};
        },
        renovationUpgradeReportType() {
            return this.document_types.find(type => type.name == 'Renovation Upgrade Report') ?? {};
        },
        progressPercent()
        {
            var total = 0;
            var max = 2;

            if(this.homeownerInformationSheet.id) {
                total++
            };
        
            if(this.renovationUpgradeReport.id) {
                total++
            }

            return (total / max) * 100;
        }

    },
    created()
    {
        this.$store.dispatch('documents/getDocumentTypes');
    }
}
</script>

<style scoped lang="scss">

#progressBar {
    transition: width 0.5s;
  }

</style>