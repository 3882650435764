let formDefaults =  {
    utility_id: {
        value: null,
        rules: 'required|integer'
    },
    utility_type_id: {
        value: null,
        rules: 'required|integer'
    },
    service_provider: {
        value: null,
        rules: 'string'
    },
}

let formConfig = {}

export { formDefaults, formConfig }