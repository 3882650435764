<template>
    <Modal :name="name" width="600" height="auto" @before-open="beforeOpen">
        <div class="p-6 pt-16 md:p-16">
            <div class="mb-6">
                <!-- Title -->
                <SectionTitle>Create Project Job</SectionTitle>
            </div>
            <!-- Form -->
            <div id="create-project-job-form" class="mb-8">

                <!-- Name -->
                <FormRow>
                    <Label for="name">Name</Label>
                    <TextInput name="name" v-model="form.name" class="2/3"></TextInput>
                    <Errors v-if="form.errors.has('name')">{{form.errors.first('name')}}</Errors>
                </FormRow>

                <!-- Project ID -->
                <FormRow>
                    <Label for="project_id">Project ID</Label>
                    <TextInput disabled name="project_id" v-model="form.project_id" class="2/3"></TextInput>
                    <Errors v-if="form.errors.has('project_id')">{{form.errors.first('project_id')}}</Errors>
                </FormRow>

                <!-- Service Type -->
                <FormRow>
                    <Label for="service_type_id">Service Type</Label>
                    <SelectInput name="service_type_id" v-model="form.service_type_id" class="2/3">
                        <option v-for="stype in service_types" :key="stype.id" :value="stype.id">{{stype.name}}</option>
                    </SelectInput>
                    <Errors v-if="form.errors.has('service_type_id')">{{form.errors.first('service_type_id')}}</Errors>
                </FormRow>

                <!-- Description -->
                <FormRow>
                    <Label for="description">Description</Label>
                    <TextareaInput name="description" v-model="form.description" class="2/3" placeholder="Write a short description..."></TextareaInput>
                    <Errors v-if="form.errors.has('description')">{{form.errors.first('description')}}</Errors>
                </FormRow>
            </div>

            <!-- Actions -->
            <FormRow>
                <square-button context="primary" class="mr-2" @click="submit">Submit</square-button>
                <square-button context="gray" @click="$modal.hide(name)">Close</square-button>
            </FormRow>
            
        </div>
    </Modal>
</template>

<script>
import Modal from '@components/Modal'
import Form from '@lukejm/form-js'
import {formDefaults} from '../../Forms/CreateProjectJobForm'
import { mapGetters } from 'vuex'

export default {
    name: 'CreateProjectJobModal',
    components: {
        Modal,
    },
    data()
    {
        return {
            name: 'create-project-job-modal',
            form: new Form(formDefaults),
        }
    },
    computed: {
        ...mapGetters({
            project: 'projects/getProject',
            service_types: 'service_types/getServiceTypes',
        }),
    },
    methods:
    {
        async beforeOpen(event)
        {
            await this.$store.dispatch('service_types/getServiceTypes');

            if(event.params?.opportunity?.service_type_id) {
                this.form.service_type_id = event.params?.opportunity?.service_type_id;
                this.form.name = `${event.params?.opportunity?.service_type?.name} Job`;
            }

            this.form.project_id = event.params?.opportunity?.project_id;
        },

        beforeClose()
        {
        },

        async submit()
        {
            await this.$store.dispatch('project_jobs/createJob', this.form.data())
                .then(() => {
                    this.form.reset();
                    this.$modal.hide(this.name);
                    this.$emit('created');

                    // this.$router.push({name: 'project_jobs.show', params: {id: '1'}});
                    
                    this.$notify({
                        title: 'Success',
                        text: 'Your project job has been added.',
                        type: 'success'
                    });
                }).catch(error => {
                    this.$notify({
                        title: 'Error',
                        text: error.message,
                        type: 'error'
                    })
                }); 
        }
    },
}
</script>
