<template>
    <div class="flex flex-col gap-4">
        <!-- Breadcrumb -->
        <Breadcrumb @click="$router.push(-1)" v-if="$route.name != 'properties.view'">Back</Breadcrumb>

        <!-- Navigation -->
        <div class="card-shadow p-2 bg-white flex flex-col gap-y-2 md:flex-row justify-between">

            <!-- Navigation -->
            <PropertyNavigationMenu class="z-40"></PropertyNavigationMenu>

            <!-- Details & Share-->
            <div class="hidden md:flex items-center justify-center gap-x-6">
                <!-- View as Admin -->
                <SquareButton v-if="isAdmin" context="white" class="items-center justify-center" @click="$router.push({name: 'admin.properties.show', params: {id: property.id}})">
                    <v-icon name="eye" class="mr-1 fill-current"></v-icon>
                    <span class="block">View as Admin</span>
                </SquareButton>
                <!-- Edit -->
                <!-- <SquareButton v-if="isAdmin" context="white" class="items-center justify-center" @click="scrollToId('#update-property-section')">
                    <v-icon name="pen" class="mr-1 fill-current"></v-icon>
                    <span class="block">Edit</span>
                </SquareButton> -->
            </div>
        </div>

        <!-- Project State [MOBILE] -->
        <!-- <Card v-if="['xs', 'sm'].includes(this.$screen.breakpoint)"> 
            <ProjectStateCard :project="projects[0]"></ProjectStateCard>
        </Card> -->

        <!-- Content -->
        <div class="flex flex-col md:flex-row gap-4">

            <!-- Left -->
            <div class="flex flex-col gap-y-8 md:flex-1">


                <div class="flex flex-col gap-y-4">


                    <h2 class="text-2xl">Next Steps</h2>
                    <!-- What to Expect -->
                    <!-- <WhatToExpect v-if="projectStateShortTitle == 'PRELIMINARY_ENERGY_EVALUATION'" :payment-link="`/projects/${firstProject.id}/payment`"></WhatToExpect> -->

                     <!-- Pay Card -->
                     <PayNowCard v-if="projectStateShortTitle == 'PRELIMINARY_ENERGY_EVALUATION'" :link="`/projects/${firstProject.id}/payment`"></PayNowCard>

                    <ExpectedSavingsCard v-if="projectStateShortTitle == 'ASSIGN_WORK'" :project="firstProject || {}" :payment-link="`/projects/${firstProject.id}/payment`"></ExpectedSavingsCard>

                    <!-- Energy Profile -->
                    <!-- <EnergyProfileCard v-if="property.energy_profile" :profile="property?.energy_profile" :documents="energyReportDocuments"></EnergyProfileCard> -->
                </div>
            
                
                <!-- Home Energy Upgrades -->
                <div class="flex flex-col gap-y-4">

                    <!-- <ActionButtonStack class="absolute top-4 right-4">
                        <template v-slot:actions>
                            <ActionButton v-if="projects.length && isAdmin" text="View Project" @click="$router.push(`/projects/${projects[0]?.id}`)"><v-icon name="eye" class="fill-current"></v-icon></ActionButton>
                        </template> 
                    </ActionButtonStack> -->

                    <!-- Title -->


                    <!-- Tasks -->
                    <!-- <div>
                        <h3 class="font-bold mb-4">Your Tasks</h3>
                        <div class="flex flex-col gap-2">
                            <TaskCard :task="task" v-for="(task, index) in projects[0]?.tasks.filter(task => task.state.title_short == 'IN_PROGRESS')" :key="index"/>
                        </div>
                    </div> -->
                    <!-- PROJECT JOBS -->
                    <div v-if="firstProject?.jobs?.length">
                        <!-- Title -->
                        <div>
                            <h2 class="text-2xl mb-4">Your Jobs</h2>
                        </div>

                        <!-- Your Upgrade Jobs [Project Jobs] -->
                        <div class="flex flex-col gap-4">
                            <JobCard :job="job" v-for="(job, index) in orderedProjectJobs" :key="index"></JobCard>
                        </div>
                    </div>

                    <!-- PROJECT OPPORTUNITIES -->
                    <div v-if="firstProject?.opportunities?.length" class="flex flex-col gap-4">
                        <!-- Title -->
                        <div class="">
                            <h2 class="text-2xl mb-4">Your Upgrade Opportunities</h2>
                            <p>Based on your home's details, we've pinpointed key areas to improve energy efficiency. Click below to explore estimated investments, as well as available government rebates and financing options available to you.</p>
                        </div>

                        <!-- <Card class="border border-orange-500">
                            <p class="mb-2">You likely qualify for the Canada Greener Homes Loan which offers access to <span class="text-orange-700">$40,000</span> at 0% interest!</p>
                            <Link href="https://natural-resources.canada.ca/energy-efficiency/homes/canada-greener-homes-initiative/canada-greener-homes-loan/24286#eligibility">Learn More</Link>
                        </Card> -->

                        <!-- Your Upgrade Opportunities [Retrofit Project Opportunities] -->
                        <div class="flex flex-col gap-4">
                            <OpportunityCard :opportunity="opportunity" v-for="(opportunity, index) in firstProject?.opportunities" :key="index"></OpportunityCard>
                        </div>
                    </div>
                </div>

                <!-- Documents -->
                <!-- <DocumentList v-if="isAdmin && !['xs', 'sm'].includes(this.$screen.breakpoint)" :records="property.documents" class="list-card" documentable="property"></DocumentList> -->

                <!-- Edit Property Card -->
                <!-- <UpdatePropertyCard id="update-property-section" v-if="isAdmin && !['xs', 'sm'].includes(this.$screen.breakpoint)" :property="property"></UpdatePropertyCard> -->
            </div>

            <!-- Right -->
            <div class="flex flex-col gap-4 md:w-1/3">

                <!-- Project State -->
                <h2 class="md:hidden text-2xl">Project State</h2>

                <div class="">
                    <Card> 
                        <ProjectStateCard :project="firstProject"></ProjectStateCard>
                    </Card>
                </div>

                <h2 class="md:hidden text-2xl">Energy Profile</h2>

                <!-- Energy Profile -->
                <EnergyProfileCard v-if="property.energy_profile" :profile="property?.energy_profile" :documents="energyReportDocuments"></EnergyProfileCard>
                

                <!-- NEXT STEPS -->
                <div class="flex flex-col gap-4">
                    <!-- Title -->
                    <!-- <h2 class="text-2xl">Next Steps</h2> -->

                    <!-- Pay Now Card -->
                    <!-- Contact Us Card -->
                    <ContactUsCard 
                        v-if="firstProject.plan?.id && firstProject.payments?.filter(payment => payment.status == 'SUCCEEDED')?.length"
                        :link="getSchedulingURL"
                    ></ContactUsCard>

                    <!-- Pay Card -->
                    <!-- <PayNowCard v-else
                        :link="`/projects/${firstProject.id}/payment`"
                        :paid="true"
                        :plan="firstProject.plan"
                    ></PayNowCard> -->



                    <!-- EnergyGuide Report CTA -->
                    <!-- <EnerGuideDocumentsCard :propertyId="property.id" :documents="energyReportDocuments"></EnerGuideDocumentsCard> -->

                    <!-- Documents [MOBILE] -->
                    <!-- <DocumentList v-if="isAdmin && ['xs', 'sm'].includes(this.$screen.breakpoint)" :records="property.documents" class="list-card" documentable="property"></DocumentList> -->

                    <!-- Edit Property [MOBILE] -->
                    <!-- <UpdatePropertyCard v-if="isAdmin && ['xs', 'sm'].includes(this.$screen.breakpoint)" id="update-property-section" :property="property"></UpdatePropertyCard> -->

                    <GeneratingEnergyReportInfoCard v-if="projectStateShortTitle == 'PRELIMINARY_ENERGY_EVALUATION' && energyReportDocuments.length >= 2 && !firstProject.jobs?.length"></GeneratingEnergyReportInfoCard>
                    <!-- <GatherQuotes v-else-if="projectStateShortTitle == 'ASSIGN_WORK' && projects[0]?.jobs?.length"></GatherQuotes> -->    
                </div>
            </div>
        
        </div>

        <!-- Admin Zone -->
        <div v-if="isAdmin" class="flex flex-col gap-4">

            <!-- Documents -->
            <!-- <DocumentList :records="property.documents" class="list-card" documentable="property"></DocumentList> -->

            <!-- Edit Property Card -->
            <!-- <UpdatePropertyCard id="update-property-section" :property="property"></UpdatePropertyCard> -->

            <!-- Appliances -->
            <!-- <ApplianceList v-if="property.appliances?.length" :records="property.appliances" class="list-card"></ApplianceList> -->

            <!-- Utilities -->
            <!-- <UtilityList v-if="property.utilities?.length" :records="property.utilities" class="list-card"></UtilityList> -->

            <!-- Rooms -->
            <!-- <Card>
                <RoomList :records="property.rooms" class="border-none -mx-4 md:-mx-6 md:-mt-4"></RoomList>
            </Card> -->
        </div>

        <UpdatePropertyModal></UpdatePropertyModal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TaskCard from '../Components/TaskCard.vue';
import OpportunityCard from '../../Projects/Components/Opportunity/OpportunityCard.vue';
import JobCard from '../../Projects/Components/Job/ProjectJobCard.vue';

import PropertyNavigationMenu from '../Components/PropertyNavigationMenu.vue';
import RoomList from '../Components/Room/RoomList.vue';
import ApplianceList from '../Components/Appliance/ApplianceList.vue';
import IncentivesTable from '../../Retrofit/Components/IncentivesTable.vue';
import UtilityList from '../Components/Utility/UtilityList.vue';
import InfoCard from '../Components/InfoCard.vue';
import StateCard from '../StateCard.vue';
import ActionButton from '../../Components/Buttons/ActionButton.vue';
import DocumentList from '../Components/DocumentList.vue';
import ProjectStateCard from '../Components/ProjectStateCard.vue';
import EnergyProfileCard from '../Components/EnergyProfile/EnergyProfileCard.vue';

import UpdatePropertyModal from '../Components/UpdatePropertyModal.vue';
// import Form from '../../Admin/Components/Form/Form.vue';
import UpdatePropertyCard from '../Components/UpdatePropertyCard.vue';
import ShareCard from '../../Retrofit/Components/ShareCard.vue';
import SectionTitle from '../../Components/SectionTitle.vue';
import DocumentInputCard from '../../Property/Components/Document/DocumentInputCard.vue';
import EnerGuideDocumentsCard from '../Components/Document/EnerGuideDocumentsCard.vue';
import WhatToExpect from '../Components/WhatToExpect.vue';
import PayNowCard from '@property/Components/PayNowCard.vue';
import ExpectedSavingsCard from '../Components/ExpectedSavingsCard.vue';
import GeneratingEnergyReportInfoCard from '../Components/GeneratingEnergyReportInfoCard.vue';
import GatherQuotes from '../Components/NextSteps/GatherQuotes.vue';
import ContactUsCard from '../Components/ContactUsCard.vue';
// import PayNowCard from '@Property/Components/PayNowCard.vue';
import ViewAsAdminCard from '../../Admin/Components/ViewAsAdminCard.vue';

import { loadClarityScript, removeClarityScript } from '../../Services/Clarity.js';



export default {
    components: {
        TaskCard,
        JobCard,
        OpportunityCard,
        PropertyNavigationMenu,
        RoomList,
        IncentivesTable,
        ApplianceList,
        UtilityList,
        InfoCard,
        StateCard,
        ActionButton,
        DocumentList,
        ProjectStateCard,
        EnergyProfileCard,
        UpdatePropertyModal,
        UpdatePropertyCard,
        ShareCard,
        SectionTitle,
        DocumentInputCard,
        EnerGuideDocumentsCard,
        WhatToExpect,
        PayNowCard,
        ExpectedSavingsCard,
        GeneratingEnergyReportInfoCard,
        GatherQuotes,
        ContactUsCard,
        ViewAsAdminCard,
    },
    data() {
        return {
            selectedIncentiveType: 'rebate',
        }
    },
    methods: {
        //
    },
    computed: {
        ...mapGetters({
            property: 'properties/getCurrentProperty',
            isAdmin: 'auth/isAdmin',
            projects: 'properties/getProjects',
            project_states: 'projects/getProjectStates',
            incentives: 'properties/getIncentives',
            user: 'auth/getUser',
        }),
        getSchedulingURL() {
            return process.env.MIX_CALENDLY_SCHEDULING_URL;
        },
        /**
         * Get project jobs ordered by annual cost savings.
         */
        orderedProjectJobs() {
            return this.firstProject?.jobs?.sort((a,b) => b.annual_cost_savings - a.annual_cost_savings)
        },
        /**
         * Get the short title of the current Project State.
         */
        projectStateShortTitle() {
            return this.projects[0]?.state?.short_title;
        },
        /**
         * Get the first project for the current Property.
         */
        firstProject() {
            return this.projects[0] || {};
        },
        /**
        * Get energy report documents for property.
         */
        energyReportDocuments()
        {
            return this.property?.documents?.filter(doc => ['Homeowner Information Sheet', 'Renovation Upgrade Report'].includes(doc.name)) || [];
        },
        /**
        * Get title for page depending on if energy reports are present.
        */
        pageTitle() {
            if(this.projects[0]?.state?.short_title == 'ASSIGN_WORK') {
                return `Your Pre-Quote Summary`;
            } else {
                return `Your Pre-Evaluation Summary`;
            }
        },
        /**
         * Get the age of the Property's current heating system.
         */
        heatingSystemAge() {
            let age = 'Unknown';
            const regex = /(\d+)(?:(?<=\.)\d*)?$/;
            if(this.property?.onboarding_data?.fields?.heating_system_age) {
                const match = this.property.onboarding_data.fields.heating_system_age.match(regex);

                if (match) {
                    age = parseInt(match[1])
                }

                return age;
            }
        },
        /**
         * Get the formatted name for the Window Type.
         */
        windowTypeFormatted() {
            if(this.property?.onboarding_data?.fields?.window_type) {
                let windowTypeFieldValue = this.property.onboarding_data.fields.window_type;
                let output = windowTypeFieldValue[0].toUpperCase() + windowTypeFieldValue.slice(1)
                return output
            }
        },
        /**
         * Total incentive amount for job.
         */
         totalIncentiveAmount()
        {
            let grantsTotal = this.projects[0]?.jobs?.reduce((total, job) => {
                return total + job.grants.reduce((total, grant) => {
                    return total + grant.amount;
                }, 0);
            }, 0) || 0;

            let rebatesTotal = this.projects[0]?.jobs?.reduce((total, job) => {
                return total + job.rebates.reduce((total, rebate) => {
                    return total + rebate.amount;
                }, 0);
            }, 0) || 0;

            // let loansTotal = this.projects[0]?.jobs?.reduce((total, job) => {
            //     return total + job.loans.reduce((total, loan) => {
            //         return total + loan.amount;
            //     }, 0);
            // }, 0) || 0;

            return grantsTotal + rebatesTotal;
        },
        /**
        * Payback period for job.
        */
        paybackPeriod()
        {
            return this.job.cost / (this.job.annual_cost_savings - this.totalIncentiveAmount);
        },
        
    },
    async created()
    {
        // Get Property
        await this.$store.dispatch('properties/getProperty', this.$route.params.id)
            .then(() => {
                // Set Page Title
                document.title = `${this.property.address.street} | ${process.env.MIX_APP_NAME}`;

                this.$store.dispatch('users/getProperties', this.user.id);
                this.$store.dispatch('properties/getProjects', this.property.id)
                this.$store.dispatch('properties/getIncentives', this.$route.params.id) 
                this.$store.dispatch('projects/getProjectStates');
            })
            .catch(error => {
                if(error?.response?.status == 403) {
                    this.$router.push(-1)
                } else {
                    throw error;
                };
            });
    },
    mounted() {
        loadClarityScript();
    },
}
</script>