<template>
    <Modal :name="name" width="800" height="auto" @before-open="beforeOpen" :scrollable="true">
        <div class="p-6 pt-16 md:p-16">
            <!-- Top -->
            <div v-if="utility.type" class="flex justify-between mb-4">
                <!-- TopLeft -->
                <div class="flex">
                    <!-- Image -->
                    <div class="h-16 w-16 mr-6">
                        <img :src="image(utility.image_url)" :alt="utility.name">
                    </div>  

                    <!-- Model & Type -->
                    <div>
                        <h3 id="utility" class="mb-1 text-lg">{{utility.name}}</h3>
                        <div class="mb-1">
                            <!-- <span>{{utility.model_number}} {{utility.year ? `(${utility.year})` : null}}</span>
                            <span> - </span> -->
                            <span id="service_provider" v-if="utility.pivot.service_provider">{{utility.pivot.service_provider}}</span>
                            <Link v-else @click="showUpdateModal">Add service provider</Link>
                        </div>
                        <!-- <p class="text-gray-700">Last Serviced: <span class="text-yellow-700">Never</span></p> -->
                    </div>
                </div>
                <!-- TopRight -->
                <!-- <div class="fold-bold">
                    {{utility.rented ? 'Rented' : null}}
                </div> -->
            </div>
            
            <!-- Associations -->
            <div class="flex flex-wrap gap-y-2 mb-12">
                <!-- Edit -->
                <Pill state="orange" :fill="true" id="update-utility-button" class="mr-2 cursor-pointer" icon="cog" @click="showUpdateModal">Edit Info</Pill>
                <!-- Utility -->
                <div v-if="utility.appliances" class="flex flex-wrap gap-y-2">
                    <Pill v-for="(appliance,index) in utility.appliances" :key="index" class="mr-2" @click="showApplianceModal(appliance)">
                        <img :src="image(appliance.type.image_url)" alt="" class="h-4 mr-2"/>
                        <span>{{appliance.type.name}}</span>
                    </Pill>
                </div>
                <!-- Room -->
                <!-- <Pill v-if="utility.room" icon="map-marker-alt" class="mr-2">{{utility.room.name}}</Pill> -->
                <!-- Utility -->
                <!-- <Pill state="green" class="mr-2" icon="bolt">Utility</Pill> -->
            </div>

            <!-- Documents -->
            <!-- <div class="mb-12">
                <div class="flex items-center mb-4">
                    <h2 class="text-lg mr-4">Documents</h2>
                    <AddButton @click="$modal.show('add-document-modal', {documentable: 'appliance'})"></AddButton>
                </div>
                <div class="flex">
                    <DocumentButton v-for="document in utility.documents" :key="document.id" :document="document"></DocumentButton>
                </div>
            </div> -->

               <!-- Notifcation -->
            <!-- <div v-if="!appliance.is_complete" class="p-3 text-sm rounded border bg-yellow-50 border-yellow-100 text-yellow-900">
                <p>You appliance information is incomplete.  Please update the following information:</p>
                <ul>
                    <li>Model</li>
                </ul>
            </div> -->

            <!-- Service Record -->
            <div class="mt-10 mb-8" v-if="false">
                <div class="flex items-center mb-8">
                    <h2 class="text-lg mr-4">Service Records</h2>
                    <!-- <AddButton @click="$modal.show('create-service-record-modal', {recordable: 'utility'})"></AddButton> -->
                </div>

                <div v-if="(utility.service_records && utility.service_records.length)">
                    <div v-for="record in utility.service_records" :key="record.id" @click="setServiceRecord(record.id)"  class="mb-4 p-4 rounded border border-1 bg-gray-50 cursor-pointer hover:bg-gray-50">
                        <!-- Upper -->
                        <div class="flex items-start justify-between">
                            <!-- Left -->
                            <div class="flex">
                                <div class="mr-4">
                                    <v-icon :name="visible_record == record.id ? 'chevron-up' : 'chevron-down'"></v-icon>
                                </div>
                                <div class="">
                                    <div class="uppercase">{{record.title}}</div>
                                    <div class="text-gray-500">{{record.service_date_short}}</div>
                                    <div class="text-gray-700 mt-4" v-show="visible_record == record.id">{{record.description}}</div>
                                    <!-- Lower -->
                                    <div class="flex items-center mt-8" v-show="visible_record == record.id">
                                        <!-- <div class="flex items-center mr-8" @click="$modal.show('add-document-modal', {documentable: 'service_record'})">
                                            <AddButton></AddButton>
                                            <span v-if="!record.documents || !record.documents.length" class="ml-2  hover:text-orange-500">Add Document</span>
                                        </div> -->
                                        <DocumentButton v-for="document in record.documents" :key="document.id" :document="document"></DocumentButton>
                                    </div>
                                </div>
                            </div>
                            <!-- Right -->
                            <div class="text-red-800">
                                <div>
                                    {{record.cost_in_local_currency}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else>
                    You do not have any service records yet. Create your first one <a href="#">here</a>.
                </div>
            </div>

            <square-button context="gray" @click="$modal.hide(name)">Close</square-button>
            
        </div>

        <!-- <AddDocumentModal></AddDocumentModal> -->
        <!-- <CreateServiceRecordModal></CreateServiceRecordModal> -->
    </Modal>
</template>

<script>
import Modal from '@components/Modal'
import { mapGetters } from 'vuex'
import AddDocumentModal from '../AddDocumentModal.vue'
// import CreateServiceRecordModal from './CreateServiceRecordModal.vue'

export default {
    components: {
        Modal,
        AddDocumentModal,
        // CreateServiceRecordModal
    },
    data()
    {
        return {
            name: 'view-utility-modal',
            visible_record: null
        }
    },
    computed: {
        ...mapGetters({
            property: 'properties/getCurrentProperty',
            utility: 'utilities/getUtility'
        }),
    },
    methods:
    {
        beforeOpen(event)
        {
            // console.log(this.utility)
        },
        showUpdateModal()
        {
            this.$modal.hide(this.name);
            this.$modal.show('update-utility-modal');
            
        },
        showApplianceModal(appliance)
        {
            //console.log(appliance);
            this.$store.commit('appliances/setAppliance', this.property.appliances.filter(app => app.id == appliance.id)[0]);
            this.$modal.hide(this.name);
            this.$modal.show('appliance-modal')
        },
    }
}
</script>
