<template>
  <Card class="flex flex-col gap-4">
    <section-title class="mb-0">
        <!-- <v-icon name="spinner" class="fill-current animate-spin mr-2"></v-icon> -->
        <span>Gather Quotes</span>
    </section-title>

    <!-- Divider -->
    <div class="border-b border-gray-200 -mt-4"></div>

    <!-- Text -->
    <div class="text-sm">
      <p>The Paddio team will contact you shortly to review your energy upgrade opportunities and discuss the generation of quotes.</p>
    </div>
  </Card>
</template>

<script>
export default {
  name: 'PaddioToContactUserFollowingJobCreation',
}
</script>
