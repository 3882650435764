<template>
  <div class="flex bg-white border w-auto border-gray-100 p-4 rounded items-center">
        <div class="w-10 h-10 mr-4 ">
            <img :src="image_url" class="w-full h-full text-gray-500 fill-current"/>
        </div>
        <div>
            <div>{{text}}</div>
            <div class="text-sm">{{subtext}}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        image_url: {
            type: String,
            required: true,
            default: () => ''
        },
        text: {
            type: String,
            required: true,
            default: () => ''
        },
        subtext: {
            type: String,
            required: true,
            default: () => ''
        }
    }
}
</script>

<style>

</style>