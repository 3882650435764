<template>
  <div class="flex flex-col md:flex-row md:items-center w-full max-w-full gap-y-2 md:gap-y-0 border-b border-gray-100 py-2">

    <!-- Label -->
    <div class="w-1/3">
        <label :for="label" class="block text-sm font-medium text-gray-700">{{ label }}</label>
    </div>

    <div class="w-full md:w-2/3 lg:w-1/2">
    
        <!-- Value & Edit Button -->
        <div v-if="!editible" class="group flex items-center">
            <!-- Field Value -->
            <div class="block text-gray-700 overflow-x-hidden">
                <!-- URL -->
                <a v-if="type=='url'" :href="text || value" target="_blank" class="link">{{ text || value }}</a>

                <!-- Date -->
                <div v-else-if="type=='date' || type=='datetime-local'"> {{ value ? (new Date(value)).toLocaleString() : '' }}</div>

                <!-- Tel -->
                <a v-else-if="type=='tel'" :href="'tel:'+value" class="hover:underline">{{ text || value }}</a>

                <!-- Email -->
                <a v-else-if="type=='email'" :href="'mailto:'+value" class="hover:underline" target="_blank">{{ text || value }}</a>

                <!-- Link -->
                <a v-else-if="link" :href="link" @click.prevent="$router.push({path: link})" class="hover:underline">{{ text || value }}</a>

                <!-- Others -->
                <span v-else class="">{{ text || value }}</span>     
            </div>
            
            <!-- Action Button -->
            <!-- Edit -->
            <button v-if="!disabled" class="ml-2 px-2 pb-1 text-gray-400 hover:text-gray-700 " @click="toggleEditMode">
                <v-icon name="pen" scale="0.75" class="fill-current"></v-icon>
            </button>
        </div>

        <!-- Text/ Number Input -->
        <div v-else class="overflow-x-hidden">

            <input v-if="type == 'text' || type == 'number'"
                type="search"
                :ref="type"
                :name="name || id" 
                :value="value" 
                @input="updateValue($event.target.value)" 
                @focus="$emit('focus', $event)"
                @focusout="$emit('focusout', $event)"
                @change="$emit('change', $event)"
                class="border border-gray-300 px-4 py-1 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm" 
                :placeholder="placeholder"
            >

            <!-- URL -->
            <input v-if="type == 'url'"
                type="url"
                :ref="type"
                :name="name || id" 
                :value="value" 
                @input="updateValue($event.target.value)" 
                @focus="$emit('focus', $event)"
                @focusout="$emit('focusout', $event)"
                @change="$emit('change', $event)"
                class="border border-gray-300 px-4 py-1 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm" 
                :placeholder="placeholder"
            >

            <!-- Date -->
            <input v-if="type == 'date' || type == 'datetime-local'"
                :type="type"
                :ref="type"
                :name="name || id" 
                :value="value" 
                @input="updateValue($event.target.value)" 
                @focus="$emit('focus', $event)"
                @focusout="$emit('focusout', $event)"
                @change="$emit('change', $event)"
                class="border border-gray-300 px-4 py-1 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm" 
                :placeholder="placeholder"
            >

            <!-- Tel -->
            <input v-if="type == 'tel'"
                type="tel"
                :ref="type"
                :name="name || id" 
                :value="value" 
                @input="updateValue($event.target.value)" 
                @focus="$emit('focus', $event)"
                @focusout="$emit('focusout', $event)"
                @change="$emit('change', $event)"
                class="border border-gray-300 px-4 py-1 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm" 
                :placeholder="placeholder">

            <!-- Email -->
            <input v-if="type == 'email'"
                type="email"
                :ref="type"
                :name="name || id" 
                :value="value" 
                @input="updateValue($event.target.value)" 
                @focus="$emit('focus', $event)"
                @focusout="$emit('focusout', $event)"
                @change="$emit('change', $event)"
                class="border border-gray-300 px-4 py-1 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm" 
                :placeholder="placeholder">
                

            <!-- select input -->
            <select 
                v-if="type == 'select'"
                :ref="type"
                :name="name || id" 
                :value="value" 
                @input="updateValue($event.target.value)" 
                @focus="$emit('focus', $event)"
                @focusout="$emit('focusout', $event)"
                @change="$emit('change', $event)"
                class="border border-gray-300 px-4 py-1 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm" 
                :placeholder="placeholder"
            >
                <slot></slot>

            </select>

            
        </div>
    </div>
        
  </div>
</template>

<script>
export default {
    name: 'TextField',
    props: {
        text: {
            type: String,
            default: '',
        },
        id: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            required: true,
        },
        value: {
            // type: String,
            required: true,
        },
        placeholder: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'text',
        },
        options: {
            type: Array,
            default: () => [{}],
        },
        mode: {
            type: String,
            default: 'view'
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        link: {
            type: String,
            default: '',
        }
    },
    data() {
        return {
            // value: '',
            edit: false,
        };
    },
    computed: {
        editible()
        {
            return this.mode == 'edit' || this.edit;
        }
    },
    methods: {
        updateValue(value) {
            this.value = value;
            this.$emit('input', value);
        },
        toggleEditMode(event) {
            this.edit = true;

            this.$refs.text?.focus() 
                || this.$refs.number?.focus() 
                || this.$refs.url?.focus()
                || this.$refs.select?.focus();
                
            this.$emit('edit');
        },
    },
    created()
    {
        // if(this.mode == 'edit') {
        //     this.edit = true;
        // }
    }
}
</script>

<style>

</style>