<template>
    <Modal :name="name" width="600" height="auto" @before-open="beforeOpen">
        <div class="p-6 pt-16 md:p-16">
           <!-- Title -->
           <SectionTitle>Add Room</SectionTitle>

            <!-- Form -->
            <div id="create-room-form" class="mb-8">

                <!-- Room Name -->
                <FormRow>
                    <Label for="name">Room Type</Label>
                    <SelectInput name="name" v-model="form.name" class="2/3">
                        <option v-for="(name, index) in room_names" :key="index" :value="name">{{name}}</option>
                        <option value="Other">Other</option>
                    </SelectInput>
                    <Errors v-if="form.errors.has('name')">{{form.errors.first('name')}}</Errors>
                </FormRow>

                <!-- Other Name -->
                <FormRow v-show="form.name == 'Other'">
                    <Label for="other_name">Custom Name</Label>
                    <TextInput name="other_name" v-model="form.other_name" class="2/3" placeholder="ie. Secret Room"/>
                    <Errors v-if="form.errors.has('other_name')">{{form.errors.first('other_name')}}</Errors>
                </FormRow>

                <!-- Nickname -->
                <FormRow>
                    <Label for="nickname">Nickname (Optional)</Label>
                    <TextInput name="nickname" v-model="form.nickname" class="2/3"/>
                    <Errors v-if="form.errors.has('nickname')">{{form.errors.first('nickname')}}</Errors>
                </FormRow>

                <!-- Storey -->
                <FormRow>
                    <Label for="floor_type">Storey</Label>
                    <TextInput name="storey" type="number" v-model="form.storey" class="2/3" placeholder="1"/>
                    <Errors v-if="form.errors.has('storey')">{{form.errors.first('storey')}}</Errors>
                    <p class="text-sm mt-2">Please use 1 for ground-level, 2 for your second level, and -1 for a basement.</p>
                </FormRow>

                <!-- Floor Type -->
                <FormRow>
                    <Label for="floor_type">Floor Type (Optional)</Label>
                    <SelectInput name="floor_type" v-model="form.floor_type" class="2/3">
                        <option v-for="(floor_type, index) in floor_types" :key="index" :value="floor_type">{{floor_type}}</option>
                        <!-- <option value="Other">Other</option> -->
                    </SelectInput>
                    <Errors v-if="form.errors.has('floor_type')">{{form.errors.first('floor_type')}}</Errors>
                </FormRow>
            </div>

            <!-- Actions -->
            <FormRow>
                <square-button context="primary" class="mr-2" @click="submit">Submit</square-button>
                <square-button context="gray" @click="$modal.hide(name)">Close</square-button>
            </FormRow>
            
        </div>
    </Modal>
</template>

<script>
import Modal from '@components/Modal'
import SectionTitle from '@components/SectionTitle'
import Form from '@lukejm/form-js'
import {formDefaults, formConfig} from '@property/Forms/CreateRoomForm.js'
import SquareButton from '@components/Buttons/SquareButton.vue'
import { mapGetters } from 'vuex'


export default {
    components: {
        Modal,
        SectionTitle,
        SquareButton
    },
    data()
    {
        return {
            name: 'create-room-modal',
            form: new Form(formDefaults, formConfig),
        }
    },
    computed:
    {
        ...mapGetters({
            property: 'properties/getCurrentProperty',
            floor_types: 'properties/getFloorTypes',
            room_names: 'rooms/getRoomNames'
        })
    },
    methods:
    {
        beforeOpen(event)
        {
            //
        },
        submit()
        {
            this.$store.dispatch('properties/addRoom', this.form.data())
                .then(() => {
                    // close modal
                    this.$modal.hide(this.name)

                    // notify user
                    this.$notify({
                        title: 'Success',
                        text: `${this.form.nickname || this.form.other_name || this.form.name} has been added to your property.`,
                        type: 'success'
                    });
                }).catch(error => {
                    this.$notify({
                        title: 'Error',
                        text: error.message,
                        type: 'error'
                    })
                });
        }
    },
    async created() {
        await this.$store.dispatch('properties/getFloorTypes');
    }
}

</script>
