<template>
    <Modal :name="name" width="600" height="auto" @before-open="beforeOpen">
        <div class="p-6 pt-16 md:p-16">
            <!-- Update Form -->
            <div>
                <!-- Title -->
                <SectionTitle>Update Utility</SectionTitle>

                    <!-- Form -->
                    <div id="update-utility-form" class="mb-8">

                        <!-- Utility Type -->
                    <!-- <FormRow>
                        <Label for="utility_type_id">Utility Type</Label>
                        <SelectInput name="utility_type_id" v-model="form.utility_type_id" class="2/3" @change="updateUtilityList">
                            <option v-for="typee in utility_types" :key="typee.id" :value="typee.id">{{typee.name}}</option>
                        </SelectInput>
                        <Errors v-if="form.errors.has('utility_type_id')">{{form.errors.first('utility_type_id')}}</Errors>
                    </FormRow> -->

                    <!-- Utility Name -->
                    <!-- <FormRow>
                        <Label for="utility_id">Utility Name</Label>
                        <SelectInput name="utility_id" v-model="form.utility_id" class="2/3">
                            <option v-for="util in selectableUtilities" :key="util.id" :value="util.id">{{util.name}}</option>
                        </SelectInput>
                        <Errors v-if="form.errors.has('utility_id')">{{form.errors.first('utility_id')}}</Errors>
                    </FormRow> -->

                    <FormRow>
                        <Label for="service_provider">Utility Service Provider (Optional)</Label>
                        <TextInput name="service_provider" v-model="form.service_provider" class="2/3" placeholder="ie. Hydro One, Hydro Québec"></TextInput>
                        <Errors v-if="form.errors.has('service_provider')">{{form.errors.first('service_provider')}}</Errors>
                    </FormRow>

                    
                </div>

                <!-- Actions -->
                <FormRow class="mb-12">
                    <square-button context="primary" class="mr-2" @click="submit">Save</square-button>
                    <square-button context="gray" @click="$modal.hide(name)">Close</square-button>
                </FormRow>
            </div>

            <!-- Delete Form -->
            <div id="delete-utility-form">
                <div class="p-4 rounded border border-red-700 border-dashed">
                    <div id="expand-delete" class="flex text-red-700 hover:text-red-900 cursor-pointer" @click="delete_expanded = !delete_expanded">
                        <div class="mr-4">
                            <v-icon class="fill-current" :name="delete_expanded ? 'chevron-up' : 'chevron-down'"></v-icon>
                        </div>
                        <div>
                            Delete
                        </div>
                    </div>
                    
                    <!-- Lower -->
                    <div class="mt-8" v-show="delete_expanded">
                        <div class="mb-6 text-red-900">
                            Do you wish do delete your utility? This cannot be undone without contacting Paddio directly.
                        </div>

                        <!-- Actions -->
                        <FormRow>
                            <square-button id="delete-utility-button" context="gray" class="mr-2" @click="deleteUtility">Delete</square-button>
                        </FormRow>

                    </div>
                </div>
            </div>
        </div>
    </Modal>
    
</template>

<script>
import Modal from '@components/Modal'
import SectionTitle from '@components/SectionTitle'
import Form from '@lukejm/form-js'
import {formDefaults, formConfig} from '@property/Forms/CreateUtilityForm.js'
import SquareButton from '@components/Buttons/SquareButton.vue'
import { mapGetters } from 'vuex'

export default {
    components: {
        Modal,
        SectionTitle,
        SquareButton,
    },
    computed: {
        ...mapGetters({
            property: 'properties/getCurrentProperty',
            utility: 'utilities/getUtility',
            utility_types: 'utilities/getUtilityTypes',
            utilities: 'utilities/getUtilities'
        })
    },
    data()
    {
        return {
            name: 'update-utility-modal',
            form: new Form(formDefaults),
            selectableUtilities: [],
            delete_expanded: false
        }
    },
    methods:
    {
        beforeOpen(event)
        {
            if(this.utility) {

                this.form.utility_type_id = this.utility.type.id;
                this.updateUtilityList(this.utility.type.id);

                this.form.utility_id = this.utility.id;
                this.form.service_provider = this.utility.pivot.service_provider;
            }
        },
        updateUtilityList(utilityTypeId)
        {
            this.selectableUtilities = this.utilities.filter(item => item.type.id == utilityTypeId);
        },
        async deleteUtility()
        {
            await this.$store.dispatch('utilities/deleteUtility', {utility: this.utility, property: this.property})
                .then(() => {

                    // TODO - update state.property.utilities
                    // TODO - update state.room.utilities
                    this.$modal.hide(this.name)
                    // this.$modal.show('utility-modal', this.$store.getters['utilities/getUtility'])

                    this.$notify({
                        title: 'Success',
                        text: `The utility has been deleted.`,
                        type: 'success'
                    });
                    }).catch(error => {
                    console.error(error);
                    this.$notify({
                        title: 'Error',
                        text: error.message,
                        type: 'error'
                    })
                });      
        },
        async submit()
        {
            await this.$store.dispatch('utilities/updateUtility', {utility: this.utility, property: this.property, fields: this.form.data()})
                .then(() => {
                    this.$modal.hide(this.name)
                    this.$modal.show('view-utility-modal')

                    this.$notify({
                        title: 'Success',
                        text: `The utility has been updated.`,
                        type: 'success'
                    });
                }).catch(error => {
                    console.error(error);

                    this.$notify({
                        title: 'Error',
                        text: error.message,
                        type: 'error'
                    })
                });
        }
    },
    async created() {
        await this.$store.dispatch('utilities/setUtilityTypes');
        await this.$store.dispatch('utilities/setUtilities');
    },
    
}
</script>
