<template>
    <Card>
        <div class="relative flex">

            <!-- Admin action -->
            <div v-if="isAdmin" class="absolute right-6 top-6 md:right-4 md:top-4">
                <ActionButtonStack>
                    <template v-slot:important-actions>
                        <!-- <ActionButton text="View Project Job" important @click="$router.push(`/jobs/${job.id}`)"><v-icon name="arrow-right" class="fill-current"></v-icon></ActionButton> -->
                    </template> 
                </ActionButtonStack>
            </div>

            <!-- Content -->
            <div class="flex flex-col gap-y-2 md:flex-row">
                <!-- Left -->
                <div class="mr-8 w-full h-32 md:h-40 md:w-40">
                    <!-- Image -->
                    <div class="w-full h-full bg-gray-100 rounded overflow-hidden">
                        <img v-if="job.image_url" :src="image(job.image_url)" :alt="job.service_type?.name" class="w-full md:w-auto h-full object-cover">
                    </div>
                </div>

                <!-- Right -->
                <div class="flex flex-1 flex-col md:w-2/5 bg-white">
                    <!-- Desription -->
                    <h2 class="text-lg mb-4">{{ job.name}}</h2>
                    <div class="text-sm">
                        <span>{{ job.description }}</span>
                        <a :href="job.learn_more_url" class="link inline-block" target="_blank">
                            <span class="block">Learn More</span>
                            <!-- <v-icon name="chevron-right" class="fill-current" scale="0.875"></v-icon> -->
                        </a>
                    </div>

                    <!-- Divider -->
                    <div class="border-b border-gray-200 my-6"></div>

                    <!-- Lower -->
                    <div>
                        <!-- Total Energy Reduction -->
                        <div class="flex font-bold mb-4">
                            <p class="mr-2">Annual Energy Reduction:</p>
                            <p class="text-orange-700">{{ job.gigajoules_saved_per_year }} GJ</p>
                        </div>

                        <!-- Details -->
                        <div class="grid grid-cols-2 md:grid-cols-4 gap-2 w-full">
                            <!-- Cost -->
                            <div class="flex flex-col">
                                <p class="text-xs lg:text-sm">Upgrade Cost</p>
                                <p class="text-orange-700">${{ Math.round(job.cost/100).toLocaleString() }}</p>
                            </div>

                            <!-- Rebates (Incentives) -->
                            <div class="flex flex-col">
                                <p class="flex flex-1 text-xs lg:text-sm">Rebates</p>
                                <p class="text-orange-700">${{ totalIncentiveAmount > 0 ? Math.round(totalIncentiveAmount/100).toLocaleString() : 0}}</p>
                            </div>

                            <!-- Annual Savings -->
                            <div class="flex flex-col">
                                <p class="flex flex-1 text-xs lg:text-sm">Annual Savings</p>
                                <p class="text-orange-700">${{ job.annual_cost_savings > 0 ? Math.round(job.annual_cost_savings/100).toLocaleString() : 0}}</p>
                            </div>

                            <!-- Payback Period-->
                            <div class="flex flex-col">
                                <p class="flex flex-1 text-xs lg:text-sm">Payback Period</p>
                                <p class="text-orange-700">{{ paybackPeriodText }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </Card>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        job: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapGetters({
            isAdmin: 'auth/isAdmin',
        }),
        /**
         * Get largest rebate for job.
         */
        getLargestRebate()
        {
            let opp = this.job.rebates.sort(this.compareByIncentiveAmount).reverse()[0] || {};
            return opp;
        },
        /**
         * Get largest grant for job.
         */
        getLargestGrant()
        {
            let opp = this.job.grants.sort(this.compareByIncentiveAmount).reverse()[0] || {};
            return opp;
        },
        /**
         * Total incentive amount for job.
         */
        totalIncentiveAmount()
        {
            return (this.getLargestRebate?.amount || 0) + (this.getLargestGrant?.amount || 0);
        },
        /**
        * Payback period for job (years).
        */
        paybackPeriod()
        {
            return ((this.job.cost - this.totalIncentiveAmount) / this.job.annual_cost_savings) || -1;
        },
        /**
        * Get Text for Paypack Period.
        */
        paybackPeriodText()
        {
            let paybackPeriod = this.job.payback_period;

            if(paybackPeriod == "Infinity") {
                return 'Unknown';
            }
            else if (paybackPeriod < 0) {
                return 'Immediate';
            }
            
            else if(paybackPeriod > 0) {
                return `${Number(paybackPeriod).toFixed(1)} years`;
            }      
        }
    },
    methods: { 
        compareByIncentiveAmount(a, b) {
            return a.amount - b.amount;
        },
    }
}
</script>