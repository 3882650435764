<template>
    <Card>
        
        <!-- Plans - if plan doesn't exist-->
        <div>
            <SectionTitle>Choose the best package for your home energy efficiency retrofit project</SectionTitle>

            <p class="mb-4 text-sm">Enhance your home's efficiency with our all-inclusive energy retrofit packages. We handle everything—relax while we transform your home into a model of efficiency. Enjoy increased comfort and savings, stress-free.</p>
            
            <div class="mb-4 flex flex-col md:flex-row gap-4 w-full">

                <!-- Energy Audit Only -->

                <ProjectPlanSummary v-for="(plan, i) in project_plans" :key="i" :plan="plan"></ProjectPlanSummary>

                <div class="hidden">
                    <!-- Upper -->
                    <div>
                        <div class="header flex flex-col flex-1">

                            <!-- Banner -->
                            <div class="w-full h-4 bg-orange-700"></div>
                            <!-- Title -->
                            <h2 class="font-bold mb-1">Energy Audit Package</h2>
                            <!-- Description -->
                            <p class="text-sm">Start your journey to an energy-efficient home with the Paddio Energy Audit Package. Get expert guidance to uncover savings and make informed decisions for your energy retrofits.</p>
                        </div>
                    </div>

                    <!-- divider -->
                    <div class="border-b border-neutral-200 my-4"></div>

                    <!-- Lower -->
                     <div>
                        <div class="text-center">
                            <!-- Prices -->
                            <div>
                                <!-- Original Price -->
                                <div class="text-xl">
                                    <sup>$</sup><span class="line-through">150</span>
                                </div>
                                <!-- Savings -->
                                <div class="text-xs">(66$ Savings)</div>
                                <!-- Price -->
                                <div class="text-xl font-bold text-orange-700"><sup>$</sup>50</div>
                            </div>

                            <!-- Subtext -->
                            <div class="text-center text-sm">For those who are curious about energy savings opportunities and doing renovations themselves.</div>
                        </div>
                        <!-- Disclaimers -->
                        <div>
                            <span class="text-xs italic">*EnerGuide Energy Audit fees not included.</span>
                        </div>
                     </div>
                </div>




                <!-- OLD -->

                <!-- Energy Audit Only -->
                <div class="hidden flex-1">
                    <div class="rounded border border-neutral-200 p-4 w-full overflow-x-hidden">

                        <div class="-mt-4 -mx-4 mb-4 px-2 py-2 rounded text-orange-700">
                            &nbsp;
                        </div>

                        <!-- Title -->
                        <div class="mb-2">
                            <div>Energy Audit Only</div>
                            <div class="text-xl text-orange-700">$50</div>
                        </div>

                        <!-- divider -->
                        <div class="border-b border-neutral-200 my-4"></div>

                        <!-- Details -->
                        <div>
                            <div class="font-bold mb-4">What you get:</div>

                            <ul class="list-style-none text-sm">
                                <li class="flex gap-x-4">
                                    <div>
                                        <v-icon name="check" class="fill-current text-green-700"></v-icon>
                                    </div>
                                    <p class="block">Paddio Concierge Scheduling Services</p>
                                </li>
                                <li class="flex gap-x-4">
                                    <div>
                                        <v-icon name="check" class="fill-current text-green-700"></v-icon>
                                    </div>
                                    <p class="block">Pre + Post-work EnerGuide Home Evaluation Management to Access Government Incentives</p>
                                </li>
                                <li class="flex gap-x-4">
                                    <div>
                                        <v-icon name="check" class="fill-current text-green-700"></v-icon>
                                    </div>
                                    <p class="block">Pre + Post-work EnerGuide Home Evaluation Management to Access Government Incentives</p>
                                </li>
                                <li class="flex gap-x-4">
                                    <div>
                                        <v-icon name="plus" class="fill-current text-red-700 transform origin-center rotate-45"></v-icon>
                                    </div>
                                    <p class="block">Paddio Project Manager and Comprehensive Project Plan Management</p>
                                </li>
                                <li class="flex gap-x-4">
                                    <div>
                                        <v-icon name="plus" class="fill-current text-red-700 transform origin-center rotate-45"></v-icon>
                                    </div>
                                    <p class="block">Access Paddio Concierge Installation Scheduling Service</p>
                                </li>
                                <li class="flex gap-x-4">
                                    <div>
                                        <v-icon name="plus" class="fill-current text-red-700 transform origin-center rotate-45"></v-icon>
                                    </div>
                                    <p class="block">Government Incentive Application Support to Maximize Savings</p>
                                </li>
                                <li class="flex gap-x-4">
                                    <div>
                                        <v-icon name="plus" class="fill-current text-red-700 transform origin-center rotate-45"></v-icon>
                                    </div>
                                    <p class="block">3-Month Post Upgrade Support</p>
                                </li>
                            </ul>
                        </div>

                        <!-- <div class="text-sm">We will take care of everything for you. From the Home EnerGuide Report to the project details.</div> -->
                    </div>
                </div>  

                <!-- Full Plan -->
                 <div class="hidden flex-1">
                    <div class="rounded border border-neutral-200 p-4 w-full overflow-x-hidden">

                        <div class="bg-orange-100 -mt-4 -mx-4 mb-4 px-2 py-2 text-orange-700">
                            Recommended - Save $50
                        </div>

                        <!-- Title -->
                        <div class="mb-2">
                            <div>Full Project Management</div>
                            <div class="flex items-center text-xl text-orange-700">
                                <span class="">$500</span>
                                <span class="line-through ml-1 text-lg">$550</span>
                            </div>
                        </div>

                        <!-- divider -->
                        <div class="border-b border-neutral-200 my-4"></div>

                        <!-- Details -->
                        <div>
                            <div class="font-bold mb-4">What you get:</div>

                            <ul class="list-style-none text-sm">
                        
                                <li class="flex gap-x-4">
                                    <div>
                                        <v-icon name="check" class="fill-current text-green-700"></v-icon>
                                    </div>
                                    <p class="block">Paddio Concierge Scheduling Services</p>
                                </li>
                                <li class="flex gap-x-4">
                                    <div>
                                        <v-icon name="check" class="fill-current text-green-700"></v-icon>
                                    </div>
                                    <p class="block">Pre + Post-work EnerGuide Home Evaluation Management to Access Government Incentives</p>
                                </li>
                                <li class="flex gap-x-4">
                                    <div>
                                        <v-icon name="check" class="fill-current text-green-700"></v-icon>
                                    </div>
                                    <p class="block">Pre + Post-work EnerGuide Home Evaluation Management to Access Government Incentives</p>
                                </li>
                                <li class="flex gap-x-4">
                                    <div>
                                        <v-icon name="check" class="fill-current text-green-700"></v-icon>
                                    </div>
                                    <p class="block">Paddio Project Manager and Comprehensive Project Plan Management</p>
                                </li>
                                <li class="flex gap-x-4">
                                    <div>
                                        <v-icon name="check" class="fill-current text-green-700"></v-icon>
                                    </div>
                                    <p class="block">Access Paddio Concierge Installation Scheduling Service</p>
                                </li>
                                <li class="flex gap-x-4">
                                    <div>
                                        <v-icon name="check" class="fill-current text-green-700"></v-icon>
                                    </div>
                                    <p class="block">Government Incentive Application Support to Maximize Savings</p>
                                </li>
                                <li class="flex gap-x-4">
                                    <div>
                                        <v-icon name="check" class="fill-current text-green-700"></v-icon>
                                    </div>
                                    <p class="block">3-Month Post Upgrade Support</p>
                                </li>
                            </ul>
                        </div>

                        <!-- <div class="text-sm">We will take care of everything for you. From the Home EnerGuide Report to the project details.</div> -->
                    </div>
                </div>

                
            </div>

            <router-link :to="link" class="block">
                <SquareButton class="w-full mb-4 text-center">Select Package</SquareButton>
            </router-link>

            <!-- <p>Still have questions? <a href="#" class="link" @click.prevent="$modal.show('create-feedback-modal')">Contact Us</a>.</p> -->
        </div>

    </Card>
</template>

<script>
import { mapGetters } from 'vuex';
import ProjectPlanSummary from './ProjectPlans/ProjectPlanSummary.vue';

export default {
    props: {
        link: {
            type: String,
            require: true,
        },
    },
    components: {
        ProjectPlanSummary,
    },
    computed: {
        ...mapGetters({
            project_plans: 'project_plans/getProjectPlans',
        })
    },
    mounted() {
        this.$store.dispatch('project_plans/getProjectPlans');
    }   
}
</script>

<style lang="scss" scoped>
    li {
        @apply mb-2;
    }
</style>