<template>
  <Card>
    <div class="group">
      <v-icon name="eye" class="fill-current mr-2"></v-icon>
      <router-link :to="{name: 'properties.view', params: {id}}" class="group-hover:underline">View as {{getUserType}}</router-link>
    </div>  
  </Card>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      // required: true
    },
    mode: {
      type: String,
      default: 'admin'
    }
  },
  computed: {
    getUserType() {
      switch(this.mode) {
        case 'admin':
          return 'User'
        case 'user':
          return 'Admin'
      }
    }
  }
}
</script>

<style>

</style>