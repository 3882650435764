<template>
  <div class="md:flex border border-gray-200 p-4 rounded items-center">

    <!-- Left -->
    <div class="flex flex-1 mb-4 md:mb-0 items-center">
        <!-- Image -->
        <div class="h-12 w-12 rounded mr-4 flex items-center justify-center">
            <v-icon class="fill-current text-gray-500" scale="2" name="clipboard-list"></v-icon>
        </div>

        <!-- Text -->
        <div>
            <div class="mb-2 md:mb-0">{{task.title}}</div>
            <div class="text-sm">{{task.description}}</div>
        </div>
    </div>

    <!-- CTA -->
    <div v-if="task.cta_text" class="md:ml-4">
        <RoundButton @click="clickCTA" class="w-full md:w-auto">{{task.cta_text}}</RoundButton>
    </div>

    </div>
</template>

<script>
export default {
    props: {
        task: {
            type: Object,
            required: true
        }
    },
    methods: {
        clickCTA() {
            this.$emit('click');
            window.open(this.task.cta_link ?? this.task.cta_url, '_blank');
        }
    }
}
</script>

<style>

</style>