<template>
    <div class="donut-chart">
      <svg width="200" height="200" viewBox="0 0 200 200">
        <g transform="translate(100,100)">
          <circle r="80" fill="#eee"></circle>
          <path v-for="(slice, index) in slices"
                :d="getPath(slice)"
                :fill="slice.color"
                :key="index">
          </path>
        </g>
      </svg>
    </div>
  </template>
  
  <script>
  export default {
    name: 'DonutChart',
    data() {
      return {
        slices: [
          { value: 10, color: '#f00' },
          { value: 20, color: '#0f0' },
          { value: 30, color: '#00f' },
          { value: 15, color: '#ff0' },
          { value: 10, color: '#f0f' },
          { value: 15, color: '#0ff' },
          // Add more slices if needed
        ],
      };
    },
    computed: {
      totalValue() {
        return this.slices.reduce((acc, slice) => acc + slice.value, 0);
      },
    },
    methods: {
      getPath(slice) {
        const x = 100;
        const y = 100;
        const radius = 80;
        const startAngle = this.getStartAngle(slice);
        const endAngle = startAngle + (slice.value / this.totalValue) * 2 * Math.PI;
        const largeArcFlag = slice.value / this.totalValue > 0.5 ? 1 : 0;
        
        const startX = x + radius * Math.cos(startAngle);
        const startY = y + radius * Math.sin(startAngle);
        const endX = x + radius * Math.cos(endAngle);
        const endY = y + radius * Math.sin(endAngle);
        
        const pathData = [
          `M ${startX} ${startY}`, // Move to start point of slice
          `A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY}`, // Arc to end point of slice
          `L ${x} ${y}`, // Line back to center of circle
          `Z` // Close path
        ].join(' ');
        
        return pathData;
      },
      getStartAngle(slice) {
        let startAngle = -Math.PI / 2; // Starting from the top
        for (const s of this.slices) {
          if (s === slice) break;
          startAngle += (s.value / this.totalValue) * 2 * Math.PI;
        }
        return startAngle;
      },
    },
  };
  </script>
  
  <style scoped>
  .donut-chart {
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  </style>
  